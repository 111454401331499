import { CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../form/Form";
import store from "../store";
import { getUserData } from "./userDashboardReducer";


export const UpdateUserDetails = () => {
    const _id1 = useSelector((state) => state.loginUserKey.userInfo?._id);
    const [cookiedata, setCookiedata] = useState({}); // Login User Id set
    const [userDetails, setUserDetails] = useState(null); // Store Response Data when Success
    const [formSubmissionState, setFormSubmissionState] = useState(0);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {

        console.log("userDetails000000000000000000000", userDetails, _id1, cookiedata);
    }, [userDetails])


    useEffect(() => {
        let reqbody = {
            source: "users",
            secret: "FXz!O7fxs",
            searchcondition: {
                _id: _id1
            },
            token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTc3NzgwMzAsImlhdCI6MTY1NzY5MTYzMH0.cl_N1oWtHWuBodVyLPXFuZYR3MKh3dZp_kc8eR-c58c",

        };
        console.log('reqbody', reqbody);
        dispatch(getUserData(reqbody))
    }, []);

    useEffect(() => {
        /////////////////// 0 for reject form submission //////////////////
         
        //////////////////// 2 for successful form submission //////////////////
        if (formSubmissionState === 2) {
            setOpen(true);
        }
    }, [formSubmissionState]);

    store.subscribe(() => {
        const storeData = store.getState();
        ////////////////////set cookie data////////////////////
        if (
            storeData &&
            storeData.loginUserKey &&
            storeData.loginUserKey.userInfo
        ) {
            setCookiedata(storeData.loginUserKey.userInfo);
        };
        if (storeData.userDashboardKey.profileinfo !== undefined && storeData.userDashboardKey.profileinfo !== null) {
            setUserDetails(storeData.userDashboardKey.profileinfo[0]);
        }
        //////////////////// Listener For form submission ///////////////////////
        setFormSubmissionState(
            storeData.formSlice.formSubmissionState.userUpdateForm
        );
    });
    const dataform = {
        id: 'userUpdateForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "api/updateuser",
        endPointBodyWrapper: "data",
        submitBtnName: "Update",
        formWrapperClass: "userUpdateForm",
        formButtonClass: "submitbuttonmyacc",
        formAdditionalSubmissionData: {
            _id: (_id1 !== undefined) ? _id1 : undefined

        },

        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                heading: "First Name",
                // label: "First Name",
                name: "firstname",
                className: 'formGroup firstname  inputcol-2',
                type: "text",
                defaultValue: (userDetails !== null && userDetails?.firstname !== undefined) ? userDetails.firstname : undefined,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
            },
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 1,
                heading: "Last Name",
                // label: "Last Name",
                name: "lastname",
                className: 'formGroup LastName inputcol-2',
                type: "text",
                defaultValue: (userDetails !== null && userDetails?.lastname !== undefined) ? userDetails.lastname : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
            },
            {
                id: 2,
                heading: "Email",
                // label: "Email",
                name: "email",
                className: 'formGroup Email inputcol-2',
                type: "text",
                disabled: true,
                defaultValue: (userDetails !== null && userDetails?.email !== undefined) ? userDetails.email : undefined,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
            },

            {
                id: 7,
                heading: "Phone No",
                // label: "Phone No",
                name: "phone",
                className: 'formGroup Phone No inputcol-2',
                type: "text",
                inputType: "",
                defaultValue: (userDetails !== null && userDetails?.phone !== undefined) ? userDetails.phone : undefined,
            },
            
            //////////////////////////////////// Text Area Input //////////////////////////
            {
                id: 3,
                heading: "Address",
                name: "address",
                // label: "Address",
                labelClass: "MuiInputLabel-root",
                className: 'formGroup Address inputcol-2',
                type: 'textarea',
                rows: 4,
                // style: { width: 400 },
                defaultValue: (userDetails !== null && userDetails?.address !== undefined) ? userDetails.address : undefined,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
            },
            {
                id: 4,
                heading: "State",
                // label: "State",
                name: "state",
                className: 'formGroup State inputcol-2',
                type: "text",
                defaultValue: (userDetails !== null && userDetails?.state !== undefined) ? userDetails.state : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },

            },
            {
                id: 5,
                heading: "City",
                // label: "City",
                name: "city",
                className: 'formGroup city inputcol-2',
                type: "text",
                defaultValue: (userDetails !== null && userDetails?.city !== undefined) ? userDetails.city : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },

            },
            {
                id: 6,
                heading: "Zip",
                // label: "Zip",
                name: "zip",
                className: 'formGroup Zip inputcol-2',
                type: "text",
                inputType: "number",
                defaultValue: (userDetails !== null && userDetails?.zip !== undefined) ? userDetails.zip : undefined,
            },
            
        ]
    };

    return (

        <div>            
            {(userDetails !== null && userDetails !== undefined) ? (
                <div className="blockInnerWrapper traning_list">
                    <h1>Profile Details</h1>
                    <div className="reactTableWrapper">
                        <Form formData={dataform} />
                    </div>
                </div>
            ) :
                <div className = "circularProgress">
                    <CircularProgress />
                </div>
            }
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={3000}
                message = "Profile Updated Successfully"
            />
        </div>

    )
}


