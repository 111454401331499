import React, { useEffect, useRef, useState } from "react";
import ListingTable from "../listing/listing";
import store from "../store";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEditData,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  openModal,
  closeModal,
  clearViewData,
  deleteSingleTableData,
  clearCustomButtonClicked,
  stopLoader,
  spinLoader,
} from "../listing/listReducer";
import moment from "moment";
import {
  Alert,
  Button,
  Drawer,
  IconButton,
  Modal,
  Skeleton,
  Snackbar,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
  LinearProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import BlogAdd from "./BlogAdd";
import { ModalBox } from "../listing/ModalBox";
import { statusUpdate } from "./userDashboardReducer";

import {

  deletemultyple,

  condeletemultyple,
  blogaddeditmultiple,
  getdataforusernext,
} from "./DashBoardReducer";

export const DashBoard = () => {
  const [loggedInUser, setLoggedInUser] = useState("");
  const [lastLoginTime, setlastLoginTime] = useState(null);
  const userName = useSelector(
    (state) =>
      state.loginUserKey.userInfo.firstname +
      " " +
      state.loginUserKey.userInfo.lastname
  );
  let loginTime = useSelector(
    (state) => state.loginUserKey.userInfo.last_login_time
  );
  loginTime = moment(loginTime).startOf("hour").fromNow();
  // loginTime = loginTime.split('T')[0];
  // setLoggedInUser(
  //   storeData.loginUserKey.userInfo.firstname +
  //   " " +
  //   storeData.loginUserKey.userInfo.lastname
  // );

  const [editdata, setEditdata] = useState({}); // Edit data set
  const [cookiedata, setCookiedata] = useState({});
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const dispatch = useDispatch();
  const [tableId, setTableId] = useState("blogTable");
  // let [responseData, setresponseData] = React.useState([]);
  // let [responseDatacontact, setresponseDatacontact] = React.useState([]);
  const [openmodal, setOpenmodal] = React.useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openmodal2, setOpenmodal2] = React.useState(false);
  const [loading, setLoading] = useState(false); // Loading set
  const [loader, setLoader] = useState(false); // Loading set
  const [fryerId, setFryerId] = useState({});
  const [isTableReload, setIsTableReload] = useState(false);
  const [openForStatus, setOpenForStatus] = useState(false);

  const [deletedID, setDeletedID] = useState([]); // Deleted ID set
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Delete modal set
  const [editStatusID, setEditStatusID] = useState([]); // Set Multiple Edit data


  const [isEdited, setIsEdited] = useState(false); // Edit Modal set

  const [isDeleted, setIsDeleted] = useState(false); // Deleted Modal set
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackber when delete success
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Deleted Success message

  const [openStatusModalAllblog, setOpenStatusModalAllblog] = useState(false);
  const [openForStatusAllblog, setOpenForStatusAllblog] = useState(false);


  const [openStatusModalAlldelete, setOpenStatusModalAlldelete] = useState(false);
  const [openForStatusAlldelete, setOpenForStatusAlldelete] = useState(false);




  const [openModalAlldeleteCON, setOpenModalAlldeleteCON] = useState(false);
  const [openForStatusAlldeleteCON, setopenForStatusAlldeleteCON] = useState(false);

  const responseData = useSelector((state)=>state.dashBoardManagement.userlistasyncdata.blogdata ?state.dashBoardManagement.userlistasyncdata.blogdata: [])
  const responseDatacontact = useSelector((state)=>state.dashBoardManagement.userlistasyncdata.contactdata ?state.dashBoardManagement.userlistasyncdata.contactdata: [])
  const tableDatas =  useSelector((state)=>state.dashBoardManagement.userlistasyncdata)

  useEffect(() => {
    const storeData = store.getState();

    if (
      storeData &&
      storeData.loginUserKey &&
      storeData.loginUserKey.userInfo
    ) {
      setCookiedata({ ...storeData.loginUserKey.userInfo });
    }
    /////////////////// 1 for pending form submission //////////////////

    //////////////////// 2 for successful form submission //////////////////
    if (formSubmissionState === 2) {
      setOpen(false);
      //////////// Only for Edit //////////////
      dispatch(clearEditData());
      setEditdata({});
      dispatch(setReloadTableFlag({ tableId: "blogTable" }));
    }
    /////////////////// 3 for rejected form submission ////////////////
  }, [formSubmissionState]);

  const closeModal = () => {
    setOpenmodal(false);
    dispatch(
      resetRowActionBtnClicked({ tableId: "blogTable", buttonId: "view002" })
    );
  };

  const closeModalcontact = () => {
    setOpenmodal2(false);
    dispatch(
      resetRowActionBtnClicked({ tableId: "contactTable", buttonId: "view003" })
    );
  };

  useEffect(() => {
    if(Object.keys(tableDatas).length == 0){
      let reqBody = {
        condition: {
          limit: 5, 
          skip: 0
        },
        searchcondition: {}
      }
      dispatch(getdataforusernext(reqBody))
    }
    console.log('openStatusModal-=-==============', openStatusModal)

  }, []);

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("Submit data", storeData.formSlice);
    console.log("Submit data", storeData.tableSlice);
    if (
      storeData.tableSlice.tempEditData.editData != undefined &&
      storeData.tableSlice.tempEditData.editData != null &&
      storeData.tableSlice.tempEditData.tableId === tableId
    ) {
      console.log("if block");
      setEditdata(storeData.tableSlice.tempEditData.editData);

      setOpen(true);
    }

    if (
      storeData.formSlice &&
      storeData.formSlice.formSubmissionState &&
      storeData.formSlice.formSubmissionState.createEventForm != undefined
    ) {
      // setOpen(false)
      setFormSubmissionState(
        storeData.formSlice.formSubmissionState.createEventForm
      );
    }

    /////////////////////////////////////// View Action Modal for shop details Catching Data Fetched Successfully /////////////////////
    if (
      storeData.tableSlice.actionLoading.blogTable !== undefined &&
      storeData.tableSlice.actionLoading.blogTable.view002 !== undefined &&
      storeData.tableSlice.actionLoading.blogTable.view002 === 2
    ) {
      setOpenmodal(true);
      viewActionModal.current.data =
        storeData.tableSlice.actionData.blogTable.view002;
    }

    //////////////////////////////////////////

    if (
      storeData.tableSlice.actionLoading.contactTable !== undefined &&
      storeData.tableSlice.actionLoading.contactTable.view003 !== undefined &&
      storeData.tableSlice.actionLoading.contactTable.view003 === 2
    ) {
      setOpenmodal2(true);
      viewActionModal2.current.data =
        storeData.tableSlice.actionData.contactTable.view003;
    }
    ////////////////////status update//////////////////////
    if (storeData.tableSlice?.tempRowData?.blogTable !== null &&
      storeData.tableSlice?.tempRowData?.blogTable !== undefined
    ) {
      setFryerId(storeData.tableSlice.tempRowData.blogTable._id);
    }
    // Open status change Modal
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.blogTable &&
      storeData.tableSlice.customButtonClicked.blogTable.statusupdate === true
    ) {
      setOpenStatusModal(true);

      console.log('first status=============================================================================', openStatusModal)
    }

    //status change  when Success
    if (
      storeData.userDashboardKey &&
      storeData.userDashboardKey.success.blogTable === true
    ) {
      if (fryerId.length > 0) {
        console.log("success 666");
        setIsTableReload(true)
      }
    }

    // Open Delete Modal
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.blogTable &&
      storeData.tableSlice.customButtonClicked.blogTable.deleteMultiple
    ) {
      setIsDeleted(true);
      setOpenDeleteModal(true);
    }
    // Open Edit Modal
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.blogTable &&
      storeData.tableSlice.customButtonClicked.blogTable.editMultiple
    ) {
      setIsEdited(true);
      setOpenDeleteModal(true);
    }

    // Multiple Edit/Delete when Success


    ///////////////////status end///////////////////

    ///////////////////status all blog///////////////////
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.blogTable &&
      storeData.tableSlice.customButtonClicked.blogTable.editMultipleAllblog === true
    ) {
      setOpenStatusModalAllblog(true);
      let arr = [];
      if (storeData.tableSlice?.multipleSelectionList?.blogTable && storeData.tableSlice.multipleSelectionList.blogTable.length > 0) {
        for (let i in storeData.tableSlice.multipleSelectionList.blogTable) {
          arr.push(storeData.tableSlice.multipleSelectionList.blogTable[i]._id);
          setEditStatusID(arr)
        }
      }


      console.log('first status=============================================================================', openStatusModalAllblog)
    }


    ///////////////////status all delete///////////////////
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.blogTable &&
      storeData.tableSlice.customButtonClicked.blogTable.deleteMultipleAllblog === true
    ) {
      setOpenStatusModalAlldelete(true);
      let arr = [];
      if (storeData.tableSlice?.multipleSelectionList?.blogTable && storeData.tableSlice.multipleSelectionList.blogTable.length > 0) {
        for (let i in storeData.tableSlice.multipleSelectionList.blogTable) {
          arr.push(storeData.tableSlice.multipleSelectionList.blogTable[i]._id);
          setEditStatusID(arr)
        }
      }


      console.log('first status=============================================================================', openStatusModalAlldelete)
    }



    ///////////////////con all delete///////////////////

    console.log(storeData, 'contactTable-----------------')
    if (
      storeData.tableSlice.customButtonClicked &&
      storeData.tableSlice.customButtonClicked.contactTable &&
      storeData.tableSlice.customButtonClicked.contactTable.deleteMultipleAllcon === true
    ) {

      setOpenModalAlldeleteCON(true);
      let arr = [];
      if (storeData.tableSlice?.multipleSelectionList?.contactTable && storeData.tableSlice.multipleSelectionList.contactTable.length > 0) {
        for (let i in storeData.tableSlice.multipleSelectionList.contactTable) {
          arr.push(storeData.tableSlice.multipleSelectionList.contactTable[i]._id);
          setEditStatusID(arr)
        }
      }


      console.log('first status=============================================================================', setOpenModalAlldeleteCON)
    }

    //status change  when Success
    if (
      storeData.userDashboardKey &&
      storeData.userDashboardKey.success.blogTable === true
    ) {
      if (fryerId.length > 0) {
        console.log("success 666");
        setIsTableReload(true)
      }
    }

    if (
      storeData.userDashboardKey &&
      storeData.userDashboardKey.success.contactTable === true
    ) {
      if (fryerId.length > 0) {
        console.log("success 666");
        setIsTableReload(true)
      }
    }

    ////////////////////////////////// For loader on action ///////////////////////////////////////////////////////////
    if (storeData.tableSlice.actionLoading?.blogTable?.view002 === 1) {
      console.log("inside action loader")
      setLoading(true);
    }
    if (storeData.tableSlice.actionLoading?.blogTable?.view002 !== 1) {
      setLoading(false);
    }

    if (storeData.tableSlice.actionLoading?.contactTable?.view003 === 1) {
      console.log("inside action loader")
      setLoader(true);
    }
    if (storeData.tableSlice.actionLoading?.contactTable?.view003 !== 1) {
      setLoader(false);
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  });

  ////////////////////////////////// Loader For Action /////////////////////
  useEffect(() => {
    if (loading === true) {
      dispatch(spinLoader({ tableId: "blogTable" }))
    } else if (loader === false) {
      dispatch(stopLoader({ tableId: "blogTable" }))
    }
  }, [loading])

  useEffect(() => {
    if (loader === true) {
      dispatch(spinLoader({ tableId: "contactTable" }))
    } else if (loader === false) {
      dispatch(stopLoader({ tableId: "contactTable" }))
    }
  }, [loader])
  ///////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    getdata(tableData.current.reqBody);
  }, []);
  var modifyTableHeaders = [
    // { val: "image", name: "Image" },
    { val: "title", name: "Title" },
    // { val: "image_show", name: "Image" },

    // { val: "description", name: "Description" },
    // {
    //   val: "createdon_datetime",
    //   name: "Creation Time",
    //   type: "datetime",
    //   format: "DD/MM/YYYY",
    // },

    { val: "priority", name: "Priority" },
    { val: "statusShow", name: "Status" },
  ];

  var modifyTableHeadersContact = [
    // { val: "image", name: "Image" },
    { val: "fullname", name: "Name" },
    { val: "email", name: "Email" },

    // { val: "message", name: "Message" },
    { val: "phone_number", name: "Phone Number" },

    // {
    //   val: "createdon_datetime",
    //   name: "Creation Time",
    //   type: "datetime",
    //   format: "DD/MM/YYYY",
    // },
  ];

  const tableData = useRef({
    tableId: "blogTable",
    tableTitle: "Blog Listing",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "api2/bloglisting",
      deleteSingleUserEndpoint: "api2/blog-delete",
      tableCountEndpoint: "api2/bloglisting-count",
    },
    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      searchcondition: {},
      sort: {
        type: "desc",
        field: "_id",
      },
      secret: "7&|B'_|ih",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTE4OTc2NTUsImRhdGEiOiJUbmxhT0ZGcFpHWm1SMnh2IiwiaWF0IjoxNjUxODExMjU1fQ.gObK1Y8u_TzIX2szsk72nj4R6FXhgvR1CLp_SFMFORo",
    },

    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
    },



    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["title", "priority"],
    // paginationType: "stackPagination",

    customBtnHead: [
      {
        id: "deleteMultipleAllblog",
        type: "delete_icon",
        title: "Delete",
      },
      {
        id: "editMultipleAllblog",
        type: "toggle_on_icon",
        title: "Status Change",
      },
    ],
    buttons: [


      {
        id: "delete",
        type: "delete",
        label: "Delete",
        name: "Delete",
      },
      {
        id: "edit1",
        type: "edit",
        label: "Edit",
        name: "Edit",
      },

      {
        id: "view002",
        type: "action",
        name: "Preview",
        icon_type: "visibility_icon",
        base_url: process.env.REACT_APP_API_URL,
        endpoint: "api2/bloglisting",
        body: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },
          searchcondition: {},
        },
        payloadAttach: ["_id"],
      },
      {
        id: "statusupdate",
        type: "other",
        label: "Status",
        name: "toggle_on_icon",
      },
    ],

    searchBarData: {
      heading: "Search Blog",
      formButtonClass: "search_bar_wrpr",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api2/bloglisting",
        tableCountEndpoint: "api2/bloglisting-count",
        reqBody: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "name",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "createdon_datetime",
            className: "date_define_wrpr "
          },






        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "statusShow",
            values: ["Active", "Inactive"],
            value: "",
            className: "status_wrpr"
          },
        ],
        textsearch: [
          { id: 1, label: "Search By Title ", field: "title", value: "", className: "search_title_wrpr" },
        ],
      },
    },
  });

  const tableDatacontct = useRef({
    tableId: "contactTable",
    tableTitle: "contact Listing",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "api2/contactlisting",
      deleteSingleUserEndpoint: "api2/contact-delete",
      tableCountEndpoint: "api2/contactlisting-count",
    },
    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      searchcondition: {},
      sort: {
        type: "desc",
        field: "_id",
      },
      secret: "7&|B'_|ih",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTE4OTc2NTUsImRhdGEiOiJUbmxhT0ZGcFpHWm1SMnh2IiwiaWF0IjoxNjUxODExMjU1fQ.gObK1Y8u_TzIX2szsk72nj4R6FXhgvR1CLp_SFMFORo",
    },
    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["title", "priority"],
    // paginationType: "stackPagination",

    customBtnHead: [
      {
        id: "deleteMultipleAllcon",
        type: "delete_icon",
        title: "Delete",
      },
    ],
    buttons: [
      {
        type: "delete",
        label: "Delete",
        name: "Delete",
      },
      //   {
      //       type: "edit",
      //       label: "Edit",
      //       name: "Edit"
      //   },

      {
        id: "view003",
        type: "action",
        name: "Preview",
        icon_type: "visibility_icon",
        base_url: process.env.REACT_APP_API_URL,
        endpoint: "api2/contactlisting",
        body: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "desc",
            field: "_id",
          },
          searchcondition: {},
        },
        payloadAttach: ["_id"],
      },
    ],




    searchBarData: {
      heading: "Search Contact",
      formButtonClass: "searchbtn_wrpr",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api2/contactlisting",
        tableCountEndpoint: "api2/contactlisting-count",
        reqBody: {
          source: "",
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "name",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "createdon_datetime",
            className: "date_wrpr"
          },
        ],

        textsearch: [
          { id: 1, label: "Search By Email ", field: "email", value: "", pattern: "", className: 'searchemail_wrpr' },
        ], selectsearch: []
      },
    },
  });

  const getdata = async () => {
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-type": "application/json" },
    //   body: JSON.stringify(tableData.current.reqBody),
    // };
    // const response = await fetch(
    //   process.env.REACT_APP_API_URL + "api2/dashboardlistasync",
    //   requestOptions
    // );
    // const respdata = await response.json();
    // console.log("respdata+++++++++", respdata);
    // if (respdata.status == "success") {
    //   if (Array.isArray(respdata.result.blogdata)) setresponseData([...respdata.result.blogdata]);
    //   setresponseDatacontact([...respdata.result.contactdata]);
    // }
  };

  const closeFormOnSubmit = () => {
    setOpen(false);
    dispatch(clearCustomButtonClicked({ tableId: "blogTable", buttonId: "editMultiple" }));
    setEditdata({});
    dispatch(clearEditData());
  };

  const closeModalOnSubmit = () => {
    setOpenForStatus(false);
    setOpenStatusModal(false);
    dispatch(clearCustomButtonClicked({ tableId: "blogTable", buttonId: "statusupdate" }));
  };


  const closeModalOnSubmitAllblog = () => {
    setOpenForStatusAllblog(false);
    setOpenStatusModalAllblog(false);
    dispatch(clearCustomButtonClicked({ tableId: "blogTable", buttonId: "editMultipleAllblog" }));
  };


  const closeModalOnSubmitAlldelete = () => {
    setOpenForStatusAlldelete(false);
    setOpenStatusModalAlldelete(false);
    dispatch(clearCustomButtonClicked({ tableId: "blogTable", buttonId: "deleteMultipleAllblog" }));
  };




  const closeModalAlldeleteCON = () => {
    setOpenModalAlldeleteCON(false);
    setopenForStatusAlldeleteCON(false);
    dispatch(clearCustomButtonClicked({ tableId: "contactTable", buttonId: "deleteMultipleAllcon" }));
  };




  const handleConfirmModalForToggleButton = async (confirm) => {
    // if(confirm!='inActive'){
    let editData = {
      source: "users",
      secret: "AZ|lepL`",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
    };

    let data = {};
    let id = [];
    data.val = 1;
    if (confirm === "inactive") {
      data.val = 0;
    }
    data.id = fryerId;
    editData.data = data;
    dispatch(statusUpdate({ editData, tableId }));
    dispatch(
      clearCustomButtonClicked({
        tableId: "blogTable",
        buttonId: "statusupdate",
      })
    );
    setOpenForStatus(true);
    dispatch(setReloadTableFlag({ tableId: "blogTable" }));
    setOpenStatusModal(false);
    // }else{
    //   setOpenStatusModal(false);
    // }
  };
  const handleConfirmModalForAllblog = async (confirm) => {
    // if(confirm!='inActive'){
    let editData = {
      source: "users",
      secret: "AZ|lepL`",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
    };

    let data = {};
    let id = [];
    data.val = 1;
    if (confirm === "inactive") {
      data.val = 0;
    }
    data.ids = editStatusID;

    editData.data = data;

    dispatch(blogaddeditmultiple(editData));
    dispatch(
      clearCustomButtonClicked({
        tableId: "blogTable",
        buttonId: "editMultipleAllblog",
      })
    );
    setOpenForStatusAllblog(true);
    dispatch(setReloadTableFlag({ tableId: "blogTable" }));
    setOpenStatusModalAllblog(false);
    // }else{
    //   setOpenStatusModalAllblog(false);
    // }
  };


// ======================blog listing======================================

  const handleConfirmModalForAlldelete = async (confirm) => {
    if (confirm != 'no') {
      let editData = {
        source: "users",
        secret: "AZ|lepL`",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
      };

      let data = {};
      let id = [];
      data.val = 1;
      data.ids = editStatusID;

      editData.data = data;

      dispatch(deletemultyple(editData));
      dispatch(
        clearCustomButtonClicked({
          tableId: "blogTable",
          buttonId: "deleteMultipleAllblog",
        })
      );
      setOpenForStatusAlldelete(true);
      dispatch(setReloadTableFlag({ tableId: "blogTable" }));
      setOpenStatusModalAlldelete(false);
    } else {
      setOpenStatusModalAlldelete(false);
      dispatch(
        clearCustomButtonClicked({
          tableId: "blogTable",
          buttonId: "deleteMultipleAllblog",
        })
      );
    }
  };

// ======================contact listing======================================

  const handleConfirmModalAlldeleteCON = async (confirm) => {
    if (confirm != 'no') {
      let editData = {
        source: "users",
        secret: "AZ|lepL`",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
      };

      let data = {};
      let id = [];
      data.val = 1;
      if (confirm === "inactive") {
        data.val = 0;
      }
      data.ids = editStatusID;

      editData.data = data;

      dispatch(condeletemultyple(editData));
   
      setopenForStatusAlldeleteCON(true);
      dispatch(setReloadTableFlag({ tableId: "contactTable" }));
      setOpenModalAlldeleteCON(false);
    } else {
      setOpenModalAlldeleteCON(false);
      dispatch(
        clearCustomButtonClicked({
          tableId: "contactTable",
          buttonId: "deleteMultipleAllcon",
        })
      );

    }
  };

  // Item Deleting Event
  // const handleConfirmModal = async (confirm, flag) => {
  //   if (flag === "deleteflag") {
  //     if (confirm) {
  //       if (deletedID.length > 0) {
  //         let onlyids = [];
  //         for (let i in deletedID) {
  //           onlyids.push(deletedID[i]._id);
  //         }
  //         dispatch(deletemultyple(onlyids));
  //       }
  //     } else {
  //       setOpenDeleteModal(false);
  //     }
  //   } else {
  //     if (editStatusID.length > 0) {
  //       let editData = {
  //         source: "users",
  //         secret: "AZ|lepL`",
  //         token:
  //           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
  //       };
  //       let data = {};
  //       let ids = [];
  //       data.val = 1;
  //       editStatusID.map((x) => ids.push(x._id));
  //       if (confirm === "inActive") {
  //         data.val = 0;
  //       }
  //       data.ids = ids;
  //       editData.data = data;
  //       dispatch(blogaddeditmultiple(editData));
  //     }
  //   }
  // };

  const viewActionModal = React.useRef({
    id: "viewActionModal",
    className: "modal1 custommodal",
    dateTimeFormat: "DD/MM/YY",
    heading: "Blog Details",
    labels: {
      title: "Title",
      description: "Description",
      image_view: "Image",

      date: "Creation Time",


      priority: "Priority",
      statusShow: "status",
    },





    data: null,
  });

  const viewActionModal2 = React.useRef({
    id: "viewActionModal2",
    className: "modal2 custommodal",
    // dateTimeFormat: "DD/MM/YY",
    heading: "Contact Details",
    labels: {
      fullname: "Name",
      phone_number: "Phone Number",
      email: "Email",
      message: "Message",
      date: "Creation Time",


    },


    data: null,
  });

  return (
    <>
      <div className="dashboard_mainwrapper">
        <div className="commonsection_backend">
          <div className="dashboard_subwrapper">
            <Grid container className="form_logindetails">
              <Grid item xs={12} className="countDiv__column--col12 loggedUser">
                {/* Logged User */}
                <div className="sectionBlock ">
                  <Card sx={{ minWidth: 275 }} className="formCls">
                    <CardContent className="formInline logged_wrpr">
                      <Typography
                        sx={{ fontSize: 26 }}
                        className="formGroup"
                        color="text.secondary"
                        gutterBottom
                      >
                        <Icon>person</Icon> Welcome: {userName}
                      </Typography>
                      <Typography
                        variant="h5"
                        className="formGroup mr20"
                        component="div"
                      >
                        <span>{loggedInUser}</span>
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        className="formGroup"
                        color="text.secondary"
                      >
                        <Icon>exit_to_app</Icon> Last Login Time: {loginTime}
                      </Typography>
                      <Typography className="formGroup mr20" variant="h5">
                        {lastLoginTime !== null && lastLoginTime}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>

            <Grid container className="GridBlogWrapper">
              <Grid item xs={12}>
                {" "}
                <div className="listtableWrapper">
                  {/* <div className="buttonListCls">
                    <Button onClick={() => setOpen(true)}>Add Blog</Button>
                  </div> */}

                  <div className="listtable_blog_wrapper">


                    <div className="buttonListCls">
                      <Button onClick={() => setOpen(true)}>Add Blog</Button>
                    </div>
                    <div className="listtable_blog">
                      {responseData && responseData.length > 0 ? (
                        <ListingTable
                          tableData={tableData.current}
                          dataset={responseData}
                          modifyHeaders={modifyTableHeaders}
                        />
                      ) : (
                        <Box>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      )}




                    </div>


                    <div className="listtable_blog">
                      {responseDatacontact && responseDatacontact.length > 0 ? (
                        <ListingTable
                          tableData={tableDatacontct.current}
                          dataset={responseDatacontact}
                          modifyHeaders={modifyTableHeadersContact}
                        />
                      ) : (
                        <Box>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                              minHeight: "50px",
                              marginBottom: "5px",
                              marginTop: "5px",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      )}
                    </div>

                  </div>
                  <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={() => closeFormOnSubmit}
                    className="con_drawer"
                  >
                    <IconButton
                      className="closeDrawer"
                      onClick={() => closeFormOnSubmit()}
                    >
                      <CloseIcon />
                    </IconButton>

                    <BlogAdd
                      closeFormOnSubmit={closeFormOnSubmit}
                      editdataforform={editdata}
                      cookieData={cookiedata}
                    />
                  </Drawer>

                  <ModalBox
                    data={viewActionModal.current}
                    close={closeModal}
                    open={openmodal}
                  />

                  <ModalBox
                    data={viewActionModal2.current}
                    close={closeModalcontact}
                    open={openmodal2}
                  />

                  <Modal
                    // className={}
                    open={openStatusModal}
                    onClose={closeModalOnSubmit}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <div className="toggle_modal">

                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "2px solid #000",
                          boxShadow: 24,
                          p: 4,
                          textAlign: "center",
                        }
                        }
                      >
                        {/* Close Button */}
                        <IconButton className="modalCloseBtn" onClick={closeModalOnSubmit}>
                          <CloseIcon />
                        </IconButton>
                        {/* Loader */}
                        {loading && (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Box>
                        )}
                        {/* For status change Modal */}
                        {
                          <>

                            <h2>

                              Are you sure you want to update status of these records ?

                            </h2>
                            <div className="modal_button_Wrpr">
                              <Button
                                onClick={() =>
                                  handleConfirmModalForToggleButton(
                                    "active",

                                  )
                                }
                              >
                                {"Active"}
                              </Button>

                              <Button
                                onClick={() =>
                                  handleConfirmModalForToggleButton(
                                    "inactive"
                                  )
                                }
                              >
                                {"Inactive"}
                              </Button>
                            </div>
                          </>
                        }
                      </Box>
                    </div>
                  </Modal>
                  <Snackbar
                    open={openForStatus}
                    onClose={() => setOpenForStatus(false)}
                    autoHideDuration={2000}
                    message="Status Updated Successfully"
                  />


                  {/* ----------------------------------- */}

                  <Modal
                    // className={}
                    open={openStatusModalAllblog}
                    onClose={closeModalOnSubmitAllblog}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <div className="toggle_modal">

                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "2px solid #000",
                          boxShadow: 24,
                          p: 4,
                          textAlign: "center",
                        }
                        }
                      >
                        {/* Close Button */}
                        <IconButton className="modalCloseBtn" onClick={closeModalOnSubmitAllblog}>
                          <CloseIcon />
                        </IconButton>
                        {/* Loader */}
                        {loading && (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Box>
                        )}
                        {/* For status change Modal */}
                        {
                          <>

                            <h2>

                              Are you sure you want to update status of these records?

                            </h2>
                            <div className="modal_button_Wrpr">
                              <Button
                                onClick={() =>
                                  handleConfirmModalForAllblog(
                                    "active",

                                  )
                                }
                              >
                                {"Active"}
                              </Button>

                              <Button
                                onClick={() =>
                                  handleConfirmModalForAllblog(
                                    "inactive"
                                  )
                                }
                              >
                                {"Inactive"}
                              </Button>
                            </div>
                          </>
                        }
                      </Box>
                    </div>
                  </Modal>
                  <Snackbar
                    open={openForStatusAllblog}
                    onClose={() => setOpenForStatusAllblog(false)}
                    autoHideDuration={2000}
                    message="Status Updated Successfully"
                  />


                  {/* ----------------------------------- */}

                  <Modal
                    // className={}
                    open={openStatusModalAlldelete}
                    onClose={closeModalOnSubmitAlldelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <div className="toggle_modal">

                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "2px solid #000",
                          boxShadow: 24,
                          p: 4,
                          textAlign: "center",
                        }
                        }
                      >
                        {/* Close Button */}
                        <IconButton className="modalCloseBtn" onClick={closeModalOnSubmitAlldelete}>
                          <CloseIcon />
                        </IconButton>
                        {/* Loader */}
                        {loading && (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Box>
                        )}
                        {/* For status change Modal */}
                        {
                          <>

                            <h2>

                              Are you sure you want to delete this record? This can't be undone.

                            </h2>
                            <div className="modal_button_Wrpr">
                              <Button
                                onClick={() =>
                                  handleConfirmModalForAlldelete(
                                    "yes",

                                  )
                                }
                              >
                                {"Yes"}
                              </Button>

                              <Button
                                onClick={() =>
                                  handleConfirmModalForAlldelete(
                                    "no"
                                  )
                                }
                              >
                                {"No"}
                              </Button>
                            </div>
                          </>
                        }
                      </Box>
                    </div>
                  </Modal>
                  <Snackbar
                    open={openForStatusAlldelete}
                    onClose={() => setOpenForStatusAlldelete(false)}
                    autoHideDuration={2000}
                    message="Delete Successfully"
                  />



                  {/* ----------------------------------- */}

                  <Modal
                    // className={}
                    open={openModalAlldeleteCON}
                    onClose={closeModalAlldeleteCON}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <div className="toggle_modal">

                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          border: "2px solid #000",
                          boxShadow: 24,
                          p: 4,
                          textAlign: "center",
                        }
                        }
                      >
                        {/* Close Button */}
                        <IconButton className="modalCloseBtn" onClick={closeModalAlldeleteCON}>
                          <CloseIcon />
                        </IconButton>
                        {/* Loader */}
                        {loading && (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Box>
                        )}
                        {/* For status change Modal */}
                        {
                          <>

                            <h2>

                              Are you sure you want to delete this record? This can't be undone.

                            </h2>
                            <div className="modal_button_Wrpr">
                              <Button
                                onClick={() =>
                                  handleConfirmModalAlldeleteCON(
                                    "yes",

                                  )
                                }
                              >
                                {"Yes"}
                              </Button>

                              <Button
                                onClick={() =>
                                  handleConfirmModalAlldeleteCON(
                                    "no"
                                  )
                                }
                              >
                                {"No"}
                              </Button>
                            </div>
                          </>
                        }
                      </Box>
                    </div>
                  </Modal>
                  <Snackbar
                    open={openForStatusAlldeleteCON}
                    onClose={() => setopenForStatusAlldeleteCON(false)}
                    autoHideDuration={2000}
                    message="Delete Successfully"
                  />




                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};