// import { Button } from "@material-ui/core";
// import { Login } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./allbackend.css";
import store from "../../src/store";
import { Alert, Grid, LinearProgress, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
// import { resetForm } from "../form/formReducer";
import { useDispatch } from "react-redux";
import { Form } from "../form/Form";
import { Link, useNavigate } from "react-router-dom";
import { calllogin, getipInfo } from "./LoginReducer";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { useCookies } from "react-cookie";
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import Tooltip from "@mui/material/Tooltip";
import { routeHelper } from "../helper/helperFunctions";
import { getdataforusernext } from "./DashBoardReducer";

store.dispatch(getipInfo());
let validation_result = false;
let ipdetails = {};

const Login = () => {
  const [loading, setloading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [opensnack, setOpensnack] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [secret, setUserSecretCookie] = useCookies(["secret"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm();
  const onSubmit = (data) => {
    data.login_data = ipdetails;
    data.login_time = Math.round(new Date().getTime());
    console.log("data==>", data);
    dispatch(calllogin(data));
  };

  store.subscribe(() => {
    const storeData = store.getState();
    ipdetails = storeData.loginUserKey.ipInfo;
    console.log('ipdetails', ipdetails);
    setloading(storeData.loginUserKey.loading);
    setLoginError(storeData.loginUserKey.errorMessage);
    setIsLoggedIn(storeData.loginUserKey.isLoggedIn)

    managenav(
      storeData,
      setuserInfoCookie,
      setUserSecretCookie,
      setUserTokenCookie,
      setLastLoginTimeCookie,
    );
  });

  useEffect(() => {
    console.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn === true) {
      setOpensnack(true);
      let reqBody = {
        condition: {
          limit: 5, 
          skip: 0
        },
        searchcondition: {}
      }
      routeHelper({
        path: "/dashboard" ,
        func: getdataforusernext,
        state: store.getState().dashBoardManagement.userlistasyncdata,
        navigate,
        body: reqBody,
      });
      // setTimeout(() => {
      // navigate("/dashboard", { replace: true });
      // }, 1500)
    }
  }, [isLoggedIn]);

  useEffect(() => {
    console.log("opensnack", opensnack);
    if (opensnack) {
      setTimeout(() => {
        setOpensnack(false);
      }, 3000);
    }
  }, [opensnack]);

  return (
    <>

<Button className="GoToHome GoToHomelogin">
          <Link to="/home">
            <Tooltip title="Go To Home">
              <OtherHousesIcon />
            </Tooltip>
          </Link>
        </Button>
      <Snackbar open={opensnack} onClose={() => setOpensnack(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={() => setOpensnack(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Login successfully
        </Alert>
      </Snackbar>
      {loading && (
                  <Box   sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
      <div className="login_mainwrapper ">
        <div className="commonsection_backend">
          <div className="login_subwrpr">
            <div className="login_text">
              <h1>
                <span>Timothy desmond</span>
              </h1>
              <h3>Author of Psyche and Singularity</h3>
            </div>

            <div className="loginform_section">
              <div className="login_form_text">
                <div className="login_form_title">
                  <h2>Welcome to Timothy Desmond </h2>
                </div>
               
                

                <div className="main_form_wrpr">
                  {/* <Form 
                  onSubmit={handleSubmit(onSubmit)}
                  formData={dataform} 
                  />

                  <Link to="/forgetpassword" className="linkText">
                    Forget Password ?
                  </Link> */}



                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="countDiv">
                      <div className="countDiv__column--col12 loginFormInputBox">
                        <label>Email*</label>
                        <input
                          className="loginFormTextInput" 
                          {...register("email", {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            },
                          })}
                        />
                        {errors.email && (
                          <p className="error">Invalid email address</p>
                        )}
                      </div>

                      <div className="countDiv__column--col12 loginFormInputBox">
                        <label>Password*</label>
                        <input
                          type="password"
                          className="loginFormTextInput"
                          {...register("password", {
                            required: true,
                            // pattern: {
                            //   value: /(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                            // }
                          })}
                        />
                        {errors.password && (
                          <p className="error">Password is required!</p>
                        )}
                        {loginError && <p className="error">{loginError}</p>}
                        {/* this code will use at the time of signup */}
                        {/* {validation_result !== true && (
                  <p className="error">{validation_result} </p>
                )} */}
                      </div>
                
                    </div>

                    {/* /////////////////////////////// LOADER //////////////////////////////
                    {loading  && (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress className="loginProgressbar" />
                      </Box>
                    )} */}
                    {loading && (
                  <Box  sx={{ width: "100%" ,marginBottom:"20px"}}>
                    <LinearProgress />
                  </Box>
                )}
                    <div className="buttonLists">
                      <Button
                        className="loginButton"
                        type="submit"
                        disabled={loading}
                      >
                        Login
                      </Button>
                    </div>
                    <Link to="/forgot-password" className="linkText">
                      Forgot Password
                    </Link>
                  </form>
                  {/* Form Section Ends */}

                </div>
              </div>
            </div>
          </div>

          {/* <div className="form_footer_section">
            <div className="form_footer_text">
              <h3>Copyright © 2000-2022 Timothy Desmond. All rights reserved.</h3>
            </div>
          </div> */}
        </div>

        <div className="form_footer_section">
            <div className="form_footer_text">
              <h3>Copyright © 2000-2022 Timothy Desmond. All rights reserved.</h3>
            </div>
          </div>

      </div>
    </>
  );
};

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserSecretCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
) {
  if (
    tempstore.loginUserKey.userInfo !== undefined &&
    tempstore.loginUserKey.userInfo._id !== null &&
    tempstore.loginUserKey.userInfo._id !== undefined
  ) {
    setuserInfoCookie(
      "userInfo",
      JSON.stringify(tempstore.loginUserKey.userInfo),
      { path: "/" }
    );

    setUserSecretCookie("secret", tempstore.loginUserKey.secret, { path: "/" });

    setUserTokenCookie("token", tempstore.loginUserKey.token, { path: "/" });

    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginUserKey.last_login_time,
      { path: "/" }
    );
  }
}
export default Login;
