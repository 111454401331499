import { Button } from '@mui/material';
import React, { useState } from 'react';
import { SetNewPassword } from './SetNewPassword';
import { UpdateUserDetails } from './UpdateUserDetails';

export const MyAccount = () => {
    const [flag, setFlag] = useState(0);

    return (
        <div className='myacdashboard'>
             <div className="commonsection_backend">
            <div >
                <div className="myacformheader_wrapper">
                <Button variant='contained' className={`myaccbtn ${flag === 0 ? "flag" : undefined}`} onClick={() => setFlag(0)}>My Account</Button>
                <Button variant='contained' className={`myaccbtn ${flag === 1 ? "flag" : undefined}`} onClick={() => (setFlag(1))}>Change Password</Button>
                </div>
            </div>
            <div>
                
                {flag === 0 &&  <UpdateUserDetails />}
                {flag === 1 && <SetNewPassword />}
            </div>
            </div>
        </div>
    ) 
}
