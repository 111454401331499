import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


////////////////////////////////// Delete Multiple Item form Table //////////////////////////////////
export const deletemultyple = createAsyncThunk("blog-list-delete", async (reqbody) => {

    let req_body = {};
    req_body = reqbody;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/blog-delete-many", requestOptions);
    const respdatafordelete = await response.json();

    return respdatafordelete;
});


////////////////////////////////// Edit Multiple Item form Table //////////////////////////////////
export const blogaddeditmultiple = createAsyncThunk("blog-list-edit-multiple", async (reqbody) => {

    let req_body = {};
    req_body = reqbody;
    console.log("reqbody 666", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/blogaddeditmultiple", requestOptions);
    const respdatafordelete = await response.json();

    return respdatafordelete;
});


////////////////////////////////// Delete Multiple Item form contact Table //////////////////////////////////
export const condeletemultyple = createAsyncThunk("contact-list-delete", async (reqbody) => {

    let req_body = {};
    req_body = reqbody;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/contact-delete-many", requestOptions);
    const respdatafordelete = await response.json();

    return respdatafordelete;
});
/////////////////////////////////for resove////////////////////////
export const getdataforusernext = createAsyncThunk(
    "dashboard/next",
    async (reqBody) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api2/dashboardlistasync`,
        requestOptions
      );
      console.log("Next 222 step", response)
      const res = await response.json();
  
      return res;
    }
  );


const dashBoardManagement = createSlice({
    name: 'dashBoardManagement',
    initialState: {
        loading: false,
        success: false,
        successmsg: null,
        editSuccess: false,
        deleteSuccess: false,
        userlistasyncdata: {},
      
    },
    reducers: {
        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.editSuccess = false;
            state.deleteSuccess = false;
            state.successmsg = null;
        }
    },
    extraReducers: {
        
        [getdataforusernext.pending]: (state, action) => {
            state.loading = true;
          },
          [getdataforusernext.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
              state.userlistasyncdata = { ...state.userlistasyncdata, ...action.payload.result };
              state.loading = false;
              state.submitting = false;
            }
          },
          [getdataforusernext.rejected]: (state, action) => {
            state.loading = false;
          },
        //////////////////////////// Delete Multiple Item ///////////////////////////////
        [deletemultyple.pending]: (state, action) => {
            state.loading = true;
        },
        [deletemultyple.fulfilled]: (state, action) => {
            console.log("action delete 666", action)
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Deleted Successfully';
                // state.success = true;
                state.deleteSuccess = true;
            }
        },
        [deletemultyple.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
        },
 
        //////////////////////////// Edit Multiple Item ///////////////////////////////
        [blogaddeditmultiple.pending]: (state, action) => {
            state.loading = true;
        },
        [blogaddeditmultiple.fulfilled]: (state, action) => {
            console.log("action update 666", action)
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Updated Successfully'
               
                state.editSuccess = true;
            }
        },
        [blogaddeditmultiple.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
        },

        //////////////////////////// Delete Multiple Item con///////////////////////////////
        
        [condeletemultyple.pending]: (state, action) => {
            state.loading = true;
        },
        [condeletemultyple.fulfilled]: (state, action) => {
            console.log("action delete 666", action)
            if (action.payload.status === "success") {
                state.loading = false;
                state.successmsg = 'Deleted Successfully';
                // state.success = true;
                state.deleteSuccess = true;
            }
        },
        [condeletemultyple.rejected]: (state, action) => {
            state.loading = false;
            state.successmsg = 'Something went wrong';
        },

        
    }
})



// export const { setSuccessAfterSubmit } = dashBoardManagement.actions;
export default dashBoardManagement.reducer;