import { configureStore } from "@reduxjs/toolkit";
import formSlice from "./form/formReducer";
import loginUser from "./tod_desmond_backend/LoginReducer";
import tableSlice from "./listing/listReducer";
import userDashboardReducer from "./tod_desmond_backend/userDashboardReducer";
import globalBackendReducer from "./globalBackendReducer";
import fileUploaderSlice from "./form/fileUploaderReducer"

import dashBoardManagement from "./tod_desmond_backend/DashBoardReducer"

export default configureStore({
    reducer: {
        loginUserKey: loginUser,
        formSlice: formSlice,
        tableSlice: tableSlice,
        fileUploaderSlice,
        loginUserKey: loginUser,
        userDashboardKey: userDashboardReducer,
        globalBackendReducer: globalBackendReducer,
        // formSlice: formSlice,
        dashBoardManagement: dashBoardManagement,
    },
});