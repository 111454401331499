import { Autocomplete, CircularProgress, FormControl, FormHelperText, InputLabel, LinearProgress, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { fetchAutoCompleteList, resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';
import lodash from 'lodash'

///////////////////// Temp Import /////////////////
import { BASE_URL, reqBody } from '../config';


export const AutoCompleteField = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const dispatch = useDispatch();

  const [val, setVal] = useState(null)
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formInputData, setFormInputData] = useState(null);
  const [tempFormInputData, setTempFormInputData] = useState(null);
  const [customError, setCustomError] = useState(null)

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }
    if (storeData.formSlice.loading[fielddata.name] !== undefined) {
      setLoading(storeData.formSlice.loading[fielddata.name]);
    }
    if (storeData.formSlice.autoCompleteList[fielddata.name] !== undefined && storeData.formSlice.autoCompleteList[fielddata.name] !== null) {
      setOptions(storeData.formSlice.autoCompleteList[fielddata.name]);
    }

    if (storeData.formSlice.formData[formId]) {
      console.log("----------------------", storeData.formSlice.formData[formId])
      setFormInputData(storeData.formSlice.formData[formId]);
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })


  //////////////////////////////////// LOADASH DEBOUNCE ////////////////////////////////////////////////////
  const debouncedFetchData = useCallback(debounce((body) => {
    dispatch(fetchAutoCompleteList({ BASE_URL: fielddata.base_url, endPoint: fielddata.endPoint, reqBody: body, restResponseAttachment: { autoCompleteId: fielddata.name, formId } }));
  }, 1000), []);

  useEffect(() => {
    if (val !== null) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) {
        setData(val)
        setValue(fielddata.name, val.map(cur => cur.key));
      }
      if (fielddata.multiple !== undefined && fielddata.multiple === false) {
        setData(val)
        setValue(fielddata.name, val.key);
      }
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])



  //////////////////////////////////////////////// Checking Value ///////////////////////////////////////////
  useEffect(() => {
    if (query !== null && query !== undefined) {
      console.log('From auto complete', BASE_URL, reqBody)
      fielddata.reqBody.searchcondition["label"] = {}

      ///////////////// For Query Search //////////////////////////
      fielddata.reqBody.searchcondition['label']["$regex"] = "^" + query;
      fielddata.reqBody.searchcondition['label']["$options"] = "ims";

      console.log("reqBody==============", fielddata.reqBody)

      debouncedFetchData(fielddata.reqBody);
    }
  }, [query]);

  useEffect(() => {
    if (fielddata.preload !== undefined && fielddata.preload === true) {
      dispatch(fetchAutoCompleteList({ BASE_URL: fielddata.base_url, endPoint: fielddata.endPoint, reqBody: fielddata.reqBody, restBodyAttachment: { autoCompleteId: fielddata.name }, restResponseAttachment: { autoCompleteId: fielddata.name, formId } }));
    }
  }, [])

  useEffect(() => {
    if (formInputData !== null && fielddata.extraParam !== undefined && fielddata.extraParam.length > 0) {
      let tempParamData = {};
      const reqBody = lodash.cloneDeep(fielddata.reqBody)
      fielddata.extraParam.forEach((param) => {
        if (formInputData[param] !== undefined) tempParamData[param] = formInputData[param];
      })
      if (Object.keys(tempParamData).length > 0) {
        Object.keys(tempParamData).forEach((param) => {

          reqBody.searchcondition[param] = {}

          /////////////////////////// For String Input ///////////////////////////
          if (typeof (tempParamData[param]) === "string" || typeof (tempParamData[param]) === "number") reqBody.searchcondition[param]["$regex"] = tempParamData[param]

          ///////////////////////// For Array Input /////////////////////////////
          if (Array.isArray(tempParamData[param]) === true) reqBody.searchcondition[param]["$in"] = tempParamData[param]
        })
        debouncedFetchData(reqBody);
      }
      console.log("search additional data", reqBody);
    }
  }, [formInputData])



  useEffect(() => {
    if (fielddata.defaultValue) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) {
        setValue(fielddata.name, fielddata.defaultValue.map(cur => cur.key));
        setData(fielddata.defaultValue)
      }
      if (fielddata.multiple !== undefined && fielddata.multiple === false) {
        setValue(fielddata.name, fielddata.defaultValue.key);
        setData(fielddata.defaultValue)
      }
    }
  }, []);




  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        {/* LOADER ON FETCH OPERATION */}
        {loading ? <LinearProgress className='autoCompleteProgressBar' /> : null}
        <Autocomplete
          multiple={fielddata.multiple}
          value={data !== null ? data : (fielddata.multiple ? [] : "")}
          defaultValue={fielddata.defaultValue ? fielddata.defaultValue : (fielddata.multiple ? [] : "")}
          onChange={(e, newValue) => {
            // console.log("newValue", newValue, e.target.value);
            ///////////////// Value set for single selection //////////////
            if (!fielddata.multiple && newValue !== null && newValue.label !== null) {
              setData(newValue)
              setValue(fielddata.name, newValue.key);
            }
            ///////////////// Value set for multiple selection //////////////
            if (fielddata.multiple && newValue !== null) {
              const tempArray = newValue.map(val => val.key);
              setData(newValue)
              setValue(fielddata.name, tempArray);
            }
          }}
          options={options}
          limitTags={fielddata.limitTags ? fielddata.limitTags : 2}
          sx={fielddata.sx}
          renderInput={(params) => {
            return <TextField
              {...params}
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <React.Fragment>
              //       {/* LOADER ON FETCH OPERATION */}
              //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
              //       {params.InputProps.endAdornment}
              //     </React.Fragment>
              //   ),
              // }}
              onChange={(e) => {
                setQuery(e.target.value);
                clearErrors(fielddata.name);
              }} label={fielddata.label} />
          }
          }
        />
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
