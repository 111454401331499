import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from "../form/Form";
import { resetForm } from '../form/formReducer';
import store from "../store";

export const ForgotPasswordPage = () => {
  let userId = useParams();
  // let token = useParams();
  console.log("userId", userId,);
  const [open, setOpen] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    /////////////////// 0 for reject form submission //////////////////

    //////////////////// 2 for successful form submission //////////////////
    if (formSubmissionState === 2) {
      setOpen(true);
      dispatch(resetForm({ formId: "forgotPasswordForm" }));
      setTimeout(() => {
        Navigate("/login")
      }, 5000)
    }
  }, [formSubmissionState]);

  store.subscribe(() => {
    const storeData = store.getState();
    //////////////////// Listener For form submission ///////////////////////
    setFormSubmissionState(
      storeData.formSlice.formSubmissionState.passwordUpdateForm
    );
  });

  const dataform = {
    id: "passwordUpdateForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/updateuser",
    endPointBodyWrapper: "data",
    submitBtnName: "UPDATE PASSWORD",
    formButtonClass: "submitbuttonmyacc",
    // urlPathOnSuccessfulFormSubmission: "/login",

    formAdditionalSubmissionData: {
      "_id": userId !== undefined ? userId._id : undefined,
      "token": userId !== undefined ? userId.token : undefined,
    },

    fields: [
      ///////////////////////////////// Password /////////////////////////////////
      {
        id: 1,
        heading: "New Password",
        label: "New Password",
        name: "password",
        className: "formdiv email",
        type: "password",
        // defaultValue: (typeof editdataforform.password !== undefined) ? editdataforform.password : undefined,

        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 2,
        heading: "Confirm New Password",
        label: "Confirm New Password",
        name: "confirmPassword",
        className: "formdiv email",
        type: "password",
        // defaultValue: (typeof editdataforform.confirmPassword !== undefined) ? editdataforform.confirmPassword : undefined,

        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          errorMessageConfirmPassword: "Password does not match", ////////////////////// Include this for confirm password field //////////
        },
      },
    ],
  };
  return (
    <div className="reset_password_wrpr">
      <div className="login_mainwrapper">
        <div className="listtableWrapper">
          <div className="login_text">
            <h1>
              <span>Timothy desmond</span>
            </h1>
            <h3>Author of Psyche and Singularity</h3>
          </div>
          <div className="commonsection_backend">
            {/* Login From Start Here */}

            <div className="reset_form_wrpr">
              {/* Form Section Starts */}
              <div className="login_text">
                <div className="loginform_section">
                  <div className="login_form_text">
                    <div className="login_form_title">
                      <h2>
                        <span>Create New Password</span>
                      </h2>
                    </div>
                    <div className="main_form_wrpr">
                      <Form formData={dataform} />

                      <Link to="/login" className="linkText">Back to Login</Link>
                      {/* Form Section Ends */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Login From Ends Here */}

            {/* <div className="form_footer_section">
              <div className="form_footer_text">
                <h3>
                  Copyright © 2000-2022 Timothy Desmond. All rights reserved.
                </h3>
              </div>
            </div> */}
          </div>

          {/* <div className="bgShape"></div>
                <div className="bgShapeTwo"></div> */}
        </div>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          message="Password Changed Successfully"
          sx = {{backgroundColor: "#fff",color: "#6B5"}}
        />

            <div className="form_footer_section">
              <div className="form_footer_text">
                <h3>
                  Copyright © 2000-2022 Timothy Desmond. All rights reserved.
                </h3>
              </div>
            </div>

      </div>
    </div>
  );
};
