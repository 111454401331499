import React, { useState } from "react";

import {
  Outlet,
  Link,
  useLocation,
  Navigate,
  useNavigate,
  NavLink,
} from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import { pink } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import { Cookies } from "react-cookie";
import { logOut } from "../../tod_desmond_backend/LoginReducer";
import store from "../../store";
import { useDispatch } from "react-redux";
import Footer from "../Footer/Footer";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DashboardIcon from "@mui/icons-material/Dashboard";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(
    store.getState().loginUserKey.isLoggedIn
  );

  // let userType = store.getState().loginUserKey.userInfo.type;

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  store.subscribe(() => {
    const storedata = store.getState();
    setIsLoggedIn(storedata.loginUserKey.isLoggedIn);
  });

  return (
    <>
      {/* <Button className="GoToHome">
        {" "}
        <Link to="/dashboard">
          {" "}
          <Tooltip title="Dashboard">
            <DashboardIcon />
          </Tooltip>{" "}
        </Link>{" "}
      </Button> */}
      {isLoggedIn ? (
        <Button className="GoToHome">
          <Link to="/dashboard">
            <Tooltip title="Dashboard">
              <DashboardIcon />
            </Tooltip>
          </Link>
        </Button>
      ) : (
        ""
      )}
      {/* {isLoggedIn ? (<Button className="GoToHome"> <Link to={`/${userType}dashboard`}>  <Tooltip title={`${userType}dashboard`} >
        <DashboardIcon />
      </Tooltip> </Link>  </Button>) : ("")} */}
      {pathname !== "/login" ? (
        <div className="header_mainwrapper">
          <div className="header_subwrapper commonsection">
            <div className="naVmobilemenu">
              <button onClick={handleToggle}>
                <MenuIcon />
              </button>
            </div>


                {/* <ul>
                  <li> <NavLink exact to={"/home"} onClick={()=>{navigate('/home');handleToggle()}}  >HOME</NavLink> </li>
                  <li> <NavLink exact to="/bio" >BIO</NavLink> </li>
                  <li> <NavLink exact to="/broadcasttrainings" >BROADCAST TRAININGS</NavLink> </li>
                  <li> <NavLink exact to="/blog"  >BLOG</NavLink> </li>
                  <li> <NavLink exact to="/contact" >CONTACT</NavLink> </li>

                </ul> */}

            <nav className={`navbar top_naVlists ${isActive ? "" : "addcss"}`}>

              <ul>

              <li> <NavLink exact to={"/home"} onClick={()=>{navigate('/home');handleToggle()}} className={splitLocation[1] === "home" ? "active" : ""} ><span>HOME</span></NavLink> </li>

              <li> <NavLink exact to={"/bio"} onClick={()=>{navigate('/bio');handleToggle()}} className={splitLocation[1] === "bio" ? "active" : ""} ><span>BIO</span></NavLink> </li>

              <li> <NavLink exact to={"/broadcasttrainings"} onClick={()=>{navigate('/broadcasttrainings');handleToggle()}} className={splitLocation[1] === "broadcasttrainings" ? "active" : ""} ><span>BROADCAST TRAININGS</span></NavLink> </li>

              <li> <NavLink exact to={"/blog"} onClick={()=>{navigate('/blog');handleToggle()}} className={splitLocation[1] === "blog" ? "active" : ""} ><span>BLOG</span></NavLink> </li>    
              
              <li> <NavLink exact to={"/contact"} onClick={()=>{navigate('/contact');handleToggle()}} className={splitLocation[1] === "contact" ? "active" : ""} ><span>CONTACT</span></NavLink> </li>   

                {/* <li onClick={()=>{navigate('/home');handleToggle()}} className={splitLocation[1] === "" ? "active" : ""}>
                 <span> HOME </span>                </li>{" "} */}

                {/* <li onClick={()=>{navigate('/bio');handleToggle()}} className={splitLocation[1] === "bio" ? "active" : ""}>
                  <span>BIO </span>                </li>{" "} */}

                {/* <li onClick={()=>{navigate('/broadcasttrainings');handleToggle()}} className={splitLocation[1] === "broadcasttrainings" ? "active" : ""}>
                <span>BROADCAST TRAININGS</span>
                </li>{" "} */}

                   {/* <li onClick={()=>{navigate('/blog');handleToggle()}} className={splitLocation[1] === "blog" ? "active" : ""}>
                  <span>BLOG</span>
                </li>{" "} */}

                {/* <li onClick={()=>{navigate('/contact');handleToggle()}} className={splitLocation[1] === "contact" ? "active" : ""}>

                 <span>CONTACT </span>
                </li>{" "} */}
                
                {/* <li className={splitLocation[1] === "contact" ? "active" : ""}>
                  <Link to="/contact"> CONTACT </Link>{" "}
                </li>{" "} */}

              </ul>{" "}
            </nav>

            <div className="social_login_wrpr">
              {isLoggedIn ? (
                <>
                  <span
                    className="login_block"
                    onClick={() => {
                      dispatch(logOut());
                      const cookies = new Cookies();
                      cookies.remove("userInfo", { path: "/" });
                      cookies.remove("secret", { path: "/" });
                      cookies.remove("token", { path: "/" });
                      navigate("/", { replace: true });
                    }}
                  >
                 <div className="login_wrpr" onClick={()=>navigate('/')}>
                      Log Out{" "}
                      <img 
                        src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Backend_asset/login_page/login_icon_new.png"
                        alt=""
                        className="img_wrpr"/>
                  </div>
                  </span>
                </>
              ) : (
                <span className="login_block">
                  {/* <img
                src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/back-end+Pages/login_icon.png" alt=""
                className="img_wrpr"
              /> */}

                  <div className="login_wrpr" onClick={()=>navigate('/login')}>
                    Login{" "}
                    <img 
                      src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Backend_asset/login_page/login_icon_new.png"
                      alt=""
                      className="img_wrpr"
                    />
                 
                 </div>
                  
                </span>
              )}

              <div className="header_socialconnect">

                <a href="https://twitter.com/" target="_blank">
                  {" "}
                  <img onClick={()=>navigate('https://twitter.com/' ) }
                    src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/twittertransparent.png"
                    alt=""
                  />{" "}
                </a>

                <a href="https://www.facebook.com/" target="_blank">
                  {" "}
                  <img
                    src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/fbtransparent.png"
                    alt=""
                  />{" "}
                </a>

                <a href="https://www.linkedin.com/" target="_blank">
                  {" "}
                  <img
                    src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/linkdintransparent.png"
                    alt=""
                  />{" "}
                </a>
              </div>
            </div>
          </div>
          <Outlet />
          {<Footer />}
        </div>
      ) : (
        ""
      )}{" "}
      <div></div>
    </>
  );
}
