import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const fetchLoggedUserData = createAsyncThunk('logged/user', async (reqBody) => {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(reqBody)
//   }

//   const response = await fetch(`${process.env.REACT_APP_API_URL}api/fetchoneloginusre`, requestOptions);

//   const resp = await response.json();

//   return resp;
// })



// export const getCityList = createAsyncThunk(
//   "adminDashboard/adminlist",
//   async (reqbody) => {
//     console.log("reqbody==>", reqbody);

//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(reqbody),
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(
//       process.env.REACT_APP_API_URL + "api/statewithCity",
//       requestOptions
//     );
//     const respdata = await response.json();
//     console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
//   }
// );


//////////////////////////////////for my account//////////////////////////////////////////
export const getUserData = createAsyncThunk("myaccount/l", async (reqbody) => {
  console.log('getuserdetailshitttttttttttttt', reqbody)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqbody),
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/userdetails`, requestOptions
  );
  console.log("getUserData==>", response);

  const resData = await response.json();
  console.log("getUserData==>", resData);

  return resData;
});
///////////////////////////////// status update //////////////////////////////////
export const statusUpdate = createAsyncThunk("statusUpdateuser", async (reqbody) => {

  let req_body = {};
  req_body = reqbody.editData;
  console.log("reqbody==", reqbody)
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req_body)
  };
  const response = await fetch(process.env.REACT_APP_API_URL + "api/onlystatusupdate", requestOptions);
  const respdataforstatusUpdate = await response.json();

  return respdataforstatusUpdate;
});

// export const addUser = createAsyncThunk(
//   "area_director-dashboard/add",
//   async (addUserBody) => {
//     let req_body = {};
//     req_body.data = addUserBody;
//     console.log("req_body==", req_body, "addUserBody", addUserBody);
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(req_body),
//     };
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}api/addorupdateusers`,
//       requestOptions
//     );
//     const addedUser = await response.json();
//     console.log("addedUser==", addedUser);
//     addedUser.newData = addUserBody;
//     return addedUser;
//   }
// );

// export const updateUser = createAsyncThunk(
//   "dashboard/updateUser",
//   async (updatedDataBody) => {
//     let req_body = {};
//     req_body.data = updatedDataBody;
//     console.log(
//       "newData and oldData ++++++++++++++++++++++++++++++++=",
//       updatedDataBody
//     );
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(req_body),
//     };
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}/api/addorupdateusers`,
//       requestOptions
//     );
//     const updatedUser = await response.json();
//     console.log("updatedUser----------------------------------", updatedUser);
//     // return updatedUser;
//   }
// );



// export const getdataforuser = createAsyncThunk(
//   "dashboard",
//   async (reqBody) => {
//     console.log('newData and oldData ++++++++++++++++++++++++++++++++=');

//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(reqBody),
//     };
//     console.log("Next step", reqBody)
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}api3/userlistasync`,
//       requestOptions
//     );
//     console.log("Next 222 step", response)
//     const updatedUserfordashboard = await response.json();
//     console.log(
//       "dashboard----------------------------------",
//       updatedUserfordashboard
//     );

//     return updatedUserfordashboard;
//   }
// );


const userdashboard = createSlice({
  name: "adminDashboard",
  initialState: {
    submitting: false,
    cities: [],
    users: [],
    profileinfo: [],
    submitted: false,
    message: null,
    userlistasyncdata: {},
    userlistasyncflag: false,
    loading: false,
    success: {},
    successmsg: "",
    store_access: 0,
    store_access_flag: false,
    tpmcount: null,
    topoffdataforpichart: null,
    pichartdayscount: null,
    loggedUserData: {},
    trainingreport: []

    // addUserData: []
  },
  reducers: {
    setSuccessAfterSubmit(state, action) {
      state.success[action.payload.tableId] = false;
    },
  },
  extraReducers: {
       /////////////////////////////////////// fetch Data of logged user ////////////////////////////
    // [fetchLoggedUserData.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [fetchLoggedUserData.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   if (action.payload.status === 'success') {
    //     state.loggedUserData = action.payload.results.res;
    //   }
    // },
    // [fetchLoggedUserData.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    // [getdataforuser.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getdataforuser.fulfilled]: (state, action) => {
    //   console.log("action", action);
    //   if (action.payload.status === "success") {
    //     // setTimeout(() => {
    //     state.userlistasyncdata = { ...action.payload.result };
    //     state.loading = false;
    //     state.userlistasyncflag = true;
    //     // console.log('Waiting.......')
    //     // }, 3000)
    //     state.submitting = false;
    //   }
    //   // state.userlistasyncdata = [...action.payload.result];
    // },
    // [getdataforuser.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    //////////////////////////////////// Fetching City List ///////////////////////////////
    // [getCityList.pending]: (state, action) => {
    //   state.cities = ["Loading..."];
    // },
    // [getCityList.fulfilled]: (state, action) => {
    //   state.cities = [...action.payload.result];
    // },
    // [getCityList.rejected]: (state, action) => {
    //   state.cities = ["No Cities Found !"];
    // },
    //////////////////////////////////// Fetching User  ///////////////////////////////\
    [getUserData.pending]: (state, action) => {
      state.submitting = true;
      state.loading = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        // setTimeout(() => {
        state.profileinfo = [action.payload.results.res[0]];
        console.log('first',action.payload.results.res[0])
        // console.log('Waiting.......')
        // }, 3000)
        state.submitting = false;
        state.loading = false;
      }
    },
    [getUserData.rejected]: (state, action) => {
      state.submitting = false;
      state.loading = false;
    },

    [statusUpdate.pending]: (state, action) => {
      state.loading = true;
    },
    [statusUpdate.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.loading = false;
        state.successmsg = 'Updated Successfully'
        state.success[action.meta.arg.tableId] = true;
      }
    },
    [statusUpdate.rejected]: (state, action) => {
      state.loading = false;
      state.successmsg = 'Something went wrong';
    },
    // ////////////////////////////////////// Adding users //////////////////////////////////
    // [addUser.pending]: (state, action) => {
    //   state.submitting = true;
    //   console.log("addUser pending.action==>", action);
    //   console.log("addUser pending.state==>", state);
    //   state.message = null;
    //   state.submitted = false;
    //   // state.addUserData.push(action.meta.arg);
    // },
    // [addUser.fulfilled]: (state, action) => {
    //   console.log("addUser.action==>", action);
    //   console.log("addUser.state==>", state);

    //   state.submitting = false;
    //   if (action.payload.status === "success") {
    //     action.payload.newData._id = action.payload.result._id;
    //     action.payload.newData.createdon_datetime =
    //       action.payload.result.createdon_datetime;
    //     console.log(
    //       "==== action.payload.newData ======",
    //       action.payload.newData
    //     );
    //     state.users = [...state.users, action.payload.newData];

    //     state.message = action.payload.message;
    //     state.submitted = true;
    //   }
    //   if (action.payload.status === "error") {
    //     state.message = action.payload.msg;
    //     state.submitted = false;
    //   }
    // },
    // [addUser.rejected]: (state, action) => {
    //   state.message = "Server Failed To Response";
    //   state.submitting = false;
    // },
    ///////////////////////////////////////////// Update User ///////////////////////////////////
    // [updateUser.pending]: (state, action) => {
    //   state.submitting = true;
    //   state.message = null;
    //   state.submitted = false;
    // },
    // [updateUser.pending]: (state, action) => {
    //   state.submitting = false;
    //   if (action.payload.status === "success") {
    //     console.log(
    //       "newData--------------------------",
    //       action.payload.newData
    //     );
    //     state.message = action.payload.msg;
    //     state.submitted = true;
    //   }
    //   if (action.payload.status === "error") {
    //     state.message = action.payload.msg;
    //     state.submitted = false;
    //   }
    // },
    // [updateUser.pending]: (state, action) => {
    //   // state.submitting = false;
    // },

  },
});
export const { setSuccessAfterSubmit, setProductAccessOfShop } = userdashboard.actions;
export default userdashboard.reducer;
