import { FormControlLabel, FormHelperText, InputLabel, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import store from '../../store';
import { resetTempFormUpdateData } from '../formReducer';

export const SwitchButton = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId }) => {
  const [val, setVal] = useState(null)

  const dispatch = useDispatch()

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }
  })


  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}

      <FormControlLabel control={
        <Switch
          checked={getValues(fielddata.name) ? getValues(fielddata.name) : false}
          onChange={(e) => {
            setValue(fielddata.name, e.target.checked);
            clearErrors(fielddata.name);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      } label={fielddata.labels !== undefined && (getValues(fielddata.name) !== undefined ? (getValues(fielddata.name) ? fielddata.labels[0] : fielddata.labels[1]) : fielddata.labels[1])} />

      {/* ERROR */}
      {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
    </div>
  )
}
