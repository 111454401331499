import { IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { timeConverter } from "../helper/helperFunctions";

export const ModalBox = ({ close, open, data }) => {
  console.log("ModalId", open, data);
  const [modalLabel, setModalLabel] = useState({});
  const [modalData, setModalData] = useState([]);
  const [modalClassName, setModalClassName] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [dateTimeFormat, setDateTimeFormat] = useState(null);
  const [style, setStyle] = useState({});

  // const dispatch = useDispatch();

  useEffect(() => {
    console.log("data", data);
    if (data?.data !== null && data?.data !== undefined && data.data !== {})
      setModalData(data.data);
    if (
      data?.labels !== null &&
      data?.labels !== undefined &&
      data.labels !== {}
    )
      setModalLabel(data.labels);
    if (
      data?.heading !== null &&
      data?.heading !== undefined &&
      data.labels !== ""
    )
      setModalHeading(data.heading);
    if (
      data?.className !== null &&
      data?.className !== undefined &&
      data.className !== ""
    )
      setModalClassName(data.className);
    if (data?.style !== null && data?.style !== undefined && data.style !== {})
      setStyle(data.style);
    if (data?.dateTimeFormat !== null && data?.dateTimeFormat !== undefined && data.dateTimeFormat !== {})
      setDateTimeFormat(data.dateTimeFormat)
  }, [open]);

  // store.subscribe(() => {
  //   const storeData = store.getState();
  //   if (storeData.tableSlice.openModal[tableId] !== undefined && storeData.tableSlice.openModal[tableId][buttonId] !== undefined) {
  //     setOpenModal(storeData.tableSlice.openModal[tableId][buttonId]);
  //   }
  //   if (storeData.tableSlice.tempModalData[tableId] !== undefined && storeData.tableSlice.tempModalData[tableId][buttonId] !== undefined) {
  //     setStyle(storeData.tableSlice.tempModalData[tableId][buttonId]?.style);
  //     setModalLabel(storeData.tableSlice.tempModalData[tableId][buttonId]?.labels);
  //     setModalData(storeData.tableSlice.tempModalData[tableId][buttonId]?.data);
  //     setModalClassName(storeData.tableSlice.tempModalData[tableId][buttonId]?.className);
  //     setModalHeading(storeData.tableSlice.tempModalData[tableId][buttonId]?.heading)
  //   }
  // })
  return (
    <>
      <Modal className={modalClassName} open={open} onClose={close}>
        <Box sx={style}>
          <IconButton onClick={close} className="CloseModal">
            <CloseIcon />
          </IconButton>
          {modalHeading && <h1>{modalHeading}</h1>}
          <div className="modalview_list">
            {modalData.map((curData, index) => {
              return (
                <div key={index} className="modalview_group">
                  {Object.keys(modalLabel).map((curLabel, i) => {
                    return (
                      <div key={i} className={modalLabel[curLabel]}>
                        <label>{modalLabel[curLabel]}:</label>
                        {curData[curLabel] ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: curLabel.includes('time') ? timeConverter(curData[curLabel], dateTimeFormat) : curData[curLabel],
                            }}
                          ></label>
                        ) : (
                          <label>NA</label>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </>
  );
};
