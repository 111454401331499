import React, { useEffect, useState } from 'react'
import { InputLabel, TextField, Icon, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import store from '../../store';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import { useDispatch } from 'react-redux';


export const Text = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)

  const dispatch = useDispatch()

  /////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])

  store.subscribe(() => {
    const storeData = store.getState();

    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  //////////////////////////////////////// Effect the field value update from outside //////////////////////////////
  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])



  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <TextField
          disabled={fielddata.disabled !== undefined ? fielddata.disabled : false}
          type={(fielddata.inputType !== undefined || fielddata.inputType !== "") ? fielddata.inputType : "text"}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            if (fielddata.inputType === "number") {
              setValue(fielddata.name, Number(e.target.value));
            } else {
              setValue(fielddata.name, e.target.value);
            }
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          label={fielddata.label}
          error={
            Object.keys(errors).length > 0 && errors[fielddata.name] != null
          }
          helperText={errors[fielddata.name] && errors[fielddata.name].type ? fielddata.errorMessage[errors[fielddata.name].type] : ""}
          InputProps={fielddata.adornments !== undefined ? (fielddata.adornments.position === "start" ? {
            startAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          } : {
            endAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          }
          ) : {}
          }
        />
      </FormControl>

    </div>
  )
}