import React from 'react'
import { Form } from "../form/Form.js";


export default function BlogAdd({ editdataforform }) {

  React.useEffect(() => {
    console.log("Edit data in Traning page", editdataforform)
  }, [])



  const dataform = {
    id: 'createEventForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api2/addblogenpoint",

    urlPathOnSuccessfulFormSubmission: "/dashboard",
    className: "submitwrpr",
    formButtonClass: "submitbuttonwrapper",
    submitBtnName: "Submit",

    formAdditionalSubmissionData: {
      // type: "admin",
      _id: (typeof editdataforform._id !== undefined) ? editdataforform._id : undefined

    },



    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        // heading: "Title",
        label: "Title",
        name: "title",
        className: 'formbox titlewrpr',
        type: "text",
        defaultValue: (typeof editdataforform.title !== undefined) ? editdataforform.title : undefined,
        rules: { required: true, },
        errorMessage: {
          required: "This Field is Required",
        },

      },

      

      {
        id: 2,
        // heading: "Priority",
        label: "Priority",
        name: "priority",
        className: ' titlewrpr prioritystyle Priority',
        // className: 'Priority prioritystyle titlewrpr',
        type: "text",
        inputType: "number",
        defaultValue: (typeof editdataforform.priority !== undefined) ? editdataforform.priority : undefined,
        rules: { required: true, maxLength: 20 },
        errorMessage: {
          required: "This Field is Required",
        },

      },

      {
        id: 12,
        label: "Unique url",
        name: "uniqueurl",
        className: 'formbox titlewrpr',
        // className: 'Priority prioritystyle titlewrpr',
        type: "text",
        defaultValue: (typeof editdataforform.uniqueurl !== undefined) ? editdataforform.uniqueurl : undefined,
        rules: { maxLength: 15 },
        errorMessage: {
          maxLength: "Unique URL can't be more than 15 charecter",
        },

      },

      {
        id: 5,
        heading: "Status",
        label: "Status",
        name: "status",
        type: "switch",
        className: "activity  activitycss titlewrpr",
        defaultValue: (typeof editdataforform.status !== undefined) ? Boolean(editdataforform.status ): false,
      },



      {
        id: 1,
        heading: "Description",
        label: "Description",
        name: "description",
        type: "sunEditor",
        className: "formbox ckediterblock",

        defaultValue: (typeof editdataforform.description !== undefined) ? editdataforform.description : undefined,
        rules: { required: true, minLength: 15 },
        errorMessage: {
          required: "This Field is Required",

        },

        placeholder: "Please type here...",
        ////////////////////////////// Mandatory or no options will be available /////////////////////
        editorWidth: 300,
        buttonListOptions: [
          ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
          ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
          ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
        ]
      },
      {
        id: 3,
        // heading: "Youtube Link",
        label: "Youtube Link",
        name: "youtubeVideo",
        className: 'formbox add_blgwrpr',
        // className: 'formbox',
        type: "youtube",
        multiple:true,
        defaultYoutubeIds: (Object.keys(editdataforform).length > 0 && typeof editdataforform.youtubeVideo !== undefined &&editdataforform.youtubeVideo !== null) ? editdataforform.youtubeVideo.map((curr) => curr.id) : undefined,
        // rules: { required: true, maxLength: 20 },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Please Enter a Valid Youtube Video Id",
          exists: "Video Link Already Added",
          custom: "Value is Invalid"
        },

      },





      {
        id: 4,
        heading: "Add Image",
        label: "Upload Image",
        className: 'Image_Box',
        name: "image",
        type: "fileUploader",
        multiple:true,
        // errorMessage: {
        //   required: "This Field is Requiredddddddddddddddddddd",
        // },

        accept: "image/*",//////// Write properly /////////
        bucket: "pece-training-files",
        path: "uploadimage/",
        base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        endpoint: "requestUploadURL",
        deleteEndpoint: "deletefilefromBucket",
        defaultFileLinks:(Object.keys(editdataforform).length > 0 && typeof editdataforform.image !== undefined) ? editdataforform.image.map((curr) => {
          return {
            url: curr.url,
            type: "image",
            name: curr.file_local_name
          }
        }) : undefined
      },

      // {
      //   id: 16,
      //   type: "html",
      //   className: "heading",
      //   value: "<div>"
      // },

      

      // {
      // id: 16,
      // type: "html",
      // className: "heading",
      // value: "</div>"
      // },

    ]
  }
  return (
    <div className='DrawerBox'>
      <h2> {Object.keys(editdataforform).length > 0 ? "Edit" : "Add"} Blog</h2>


      <Form formData={dataform} />

    </div>
  )
}
