import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import store from '../../store'
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer'

export const SelectField = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)

  const dispatch = useDispatch()
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) setValue(fielddata.name, [fielddata.defaultValue]);
      else setValue(fielddata.name, fielddata.defaultValue);
    }

  }, []);

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl
        sx={fielddata.sx}
      >
        {/* Label */}
        {fielddata.label && <InputLabel >{fielddata.label}</InputLabel>}
        <Select
          label={fielddata.label}
          multiple={fielddata.multiple !== undefined && typeof (fielddata.multiple) === "boolean" ? fielddata.multiple : false}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : (fielddata.multiple === true ? [] : '')}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          displayEmpty
        >
          {fielddata.values.map((value, index) => <MenuItem key={index} value={value.val}>{value.name}</MenuItem>)}
        </Select>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
