// import { Button } from "@material-ui/core";
// import { Login } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./allbackend.css";
import store from "../../src/store";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
// import { resetForm } from "../form/formReducer";
import { useDispatch } from "react-redux";
import { Form } from "../form/Form";
import { Link } from "react-router-dom";

const Login = () => {
  const [loading, setloading] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [opensnack, setOpensnack] = useState(false);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  store.subscribe(() => {
    const storeData = store.getState();
    setFormSubmissionState(
      storeData.formSlice.formSubmissionState.createEventForm
    );
    console.log("storeData in pagwwwwww", storeData.formSlice);
    if (storeData.formSlice.formSubmissionState.createEventForm === 2) {
      setOpensnack(true);
    }
    if (storeData.formSlice.message?.createEventForm) {
      setMessage(storeData.formSlice.message.createEventForm)
    }
  });

  useEffect(() => {
    if (formSubmissionState == 1) {
      setloading(true);
    }

    if (formSubmissionState == 2) {
      setloading(false);
      // dispatch(resetForm({ formId: "createEventForm" }));
    }
  }, [formSubmissionState]);

  useEffect(() => {
    console.log("opensnack", opensnack);
    if (opensnack) {
      setTimeout(() => {
        setOpensnack(false);
      }, 3000);
    }
  }, [opensnack]);

  const dataform = {
    id: "createEventForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/forgotpassword",
    endPointBodyWrapper: "data",
    submitBtnName: "SUBMIT",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formButtonClass: "submitbtnsection",

    formAdditionalSubmissionData: {
      link: `${process.env.REACT_APP_API_EXTERNAL_URL}forgot-password/`,

    },

    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////

      {
        id: 1,
        // heading: "Email",
        label: "Email",
        name: "email",
        className: "formdiv email",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
    ],
  };




  return (
    <>
      <Snackbar open={opensnack}
        onClose={() => {
          setOpensnack(false)
          setMessage(null)
        }
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={message}
        sx={{ backgroundColor: "#fff", color: "#6B5" }}
      />

      <div className="forget_mainwrapper ">
        <div className="login_mainwrapper ">
          <div className="commonsection_backend">
            <div className="login_subwrpr">
              <div className="login_text">
                <h1>
                  <span>Timothy desmond</span>
                </h1>
                <h3>Author of Psyche and Singularity</h3>
              </div>

              <div className="loginform_section">
                <div className="login_form_text">
                  <div className="login_form_title">
                    <h1>
                      <span style={{ color: "white" }}>PASSWORD ASSISTANCE</span>

                    </h1>
                  </div>

                  {loading && (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  )}
                  {/* <div className="login_form_title">
                  <h2>Welcome to Timothy Desmond </h2>
                </div> */}

                  <div className="main_form_wrpr">
                    <h3>
                      <span style={{ color: "white" }}>Enter the email address associated with your user account</span>
                    </h3>
                    <Form formData={dataform} />
                    <Link to="/login" className="linkText">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="form_footer_section">
              <div className="form_footer_text">
                <h3>
                  Copyright © 2000-2022 Timothy Desmond. All rights reserved.
                </h3>
              </div>
            </div> */}
          </div>
          
          <div className="form_footer_section">
              <div className="form_footer_text">
                <h3>
                  Copyright © 2000-2022 Timothy Desmond. All rights reserved.
                </h3>
              </div>
            </div>

        </div>
      </div>
    </>
  );
};

export default Login;