import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';

export const DatePickerField = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError, formId }) => {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)


  const dispatch = useDispatch()
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, []);

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }


    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={fielddata.label}
            inputFormat={fielddata.dateFormat}
            value={getValues(fielddata.name) ? getValues(fielddata.name) : null}//////////// new Date set as default value ////////////////
            onChange={(e) => {
              if (e === null) {
                setError(fielddata.name, { type: "required" }, { shouldFocus: true });
                return;
              }
              setValue(fielddata.name, new Date(e.toString()).getTime());
              clearErrors(fielddata.name);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
