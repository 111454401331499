import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AdminHeader from "./layout/Header/adminheader";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./layout/Footer/Footer";

const Layout = () => {
  console.log("layout console");

  const userType = useSelector((state) => state.loginUserKey.userInfo.type);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Location layout changed", location, location.pathname);
  }, [location]);
  // const param=user
  if (
    location.pathname === "/" ||
    location.pathname === "/login" ||
    location.pathname === "/forget-password" ||
    location.pathname === "/signup"
  ) {
    document.body.className += " noPadding";
  } else {
    document.body.className += " Padding";
  }

//   useEffect(() => {
//     dispatch(fetchUserCount());
//   }, []);

  return (
    <>
      <div className="mainContent p30TB">
        {/* {(location.pathname === '/admin-dashboard' || location.pathname === '/trainings/lessons' || location.pathname === '/admin-dashboard/add-edit-admin' || location.pathname === '/admin-dashboard/add-edit-owner' || location.pathname === '/area_director-dashboard/area-directors/list' || location.pathname === '/area_director-dashboard/area-directors/add' || location.pathname === '/area-directors/list' || location.pathname === '/trainings/training/add' || location.pathname === '/trainings/training' || location.pathname === '/trainings/training/add-edit-training-catagory' || location.pathname === '/trainings/lessons/add' || location.pathname === '/admin-dashboard/add-edit-manager' || location.pathname === '/product/list' || location.pathname === '/users' || location.pathname === '/add-edit-traning' || location.pathname === '/list-training' || location.pathname === '/list-lessons' || location.pathname === '/list-shop' || location.pathname.includes("trainingcenter") || (location.pathname.includes("vat-list") && (store.getState().loginUserKey.userInfo.type == "admin"))) &&
          <AdminHeader />
        } */}
        {(location.pathname === "/dashboard" || location.pathname === "/myaccount" || location.pathname === "/blogadd" || location.pathname === "/bloglist") &&(
          <AdminHeader />
        )}
        {/* <Header /> */}
        <div className="pageMain">
          <Outlet />
        </div>

        {((location.pathname !== "/" &&
          location.pathname !== "/forget-password" &&
          location.pathname === "/dashboard" ||  location.pathname === "/blogadd" || location.pathname === "/bloglist")) && <div className="copyright_text_wrpr"><p>Copyright © 2000-2022 Timothy Desmond. All rights reserved.</p></div>}
          
        {/* {(location.pathname !== "/dashboard") && <Footer />} */}
      </div>
    </>
  );
};

export default Layout;
