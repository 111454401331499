import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReplayIcon from "@mui/icons-material/Replay";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Drawer,
  Icon,
  LinearProgress,
  Modal,
  Pagination,
  Snackbar,
  Stack,
  SwipeableDrawer,
} from "@mui/material";
import { useDispatch } from "react-redux";
import store from "../store";
import {
  clearCustomButtonClicked,
  deleteSingleTableData,
  fetchTableCount,
  fetchTableList,
  onRowActionButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  resetRowActionData,
  resetDeleteSingleDataFlag,
  setCustomButtonClicked,
  setMultipleSeletedList,
  setTableData,
  setTableFieldsData,
  setTableRowData,
  setTempEditData,
  setViewData,
  setTableDefaultreqBody,
} from "./listReducer";
import { SearchBar } from "../search/SearchBar";
import {
  randomId,
  requestSequencer,
  timeConverter,
} from "../helper/helperFunctions";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}
var headersArray = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableHead(props) {
  console.log("headersArray", headersArray);
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableData,
    setPage,
    setOrder,
    setSelected,
    setSortDirection,
    sortDirection,
    defaultReqBody,
  } = props;
  const createSortHandler = (property, tableData, orderBy) => (event) => {
    console.log(
      "EVENT+++++ON SORT++",
      event.target,
      event.target.classList.contains("MuiTableSortLabel-iconDirectionDesc"),
      event.target.classList.contains("MuiTableSortLabel-iconDirectionAsc"),
      property,
      tableData
    );
    console.log("SORT++++++++++++++++++++++");
    if (tableData.reqBody.sort.type === "desc") {
      console.log("DESC_________________");
      tableData.reqBody.sort.type = "asc";
    } else {
      tableData.reqBody.sort.type = "desc";
    }
    if (property !== orderBy) {
      console.log("CHANGE NODE++++++++++========");
      tableData.reqBody.sort.type = "desc";
    }
    const newSortField = property;
    tableData.reqBody.sort.field = newSortField;
    tableData.reqBody.condition.skip = 0;
    onHandleReload({
      defaultReqBody,
      tableData,
      setSelected,
      setSortDirection,
    });
    onRequestSort(event, property);

    setOrder(tableData.reqBody.sort.type);
    setPage(0);

    setSortDirection((prev) => {
      if (tableData.reqBody.sort.type === "desc")
        return { ...prev, [property]: "asc" };
      else return { ...prev, [property]: "desc" };
    });
  };

  return (
    <TableHead>
      <TableRow>
        {tableData.customBtnHead !== undefined &&
          tableData.customBtnHead.length > 0 && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
          )}

        {/* Index Header */}
        <TableCell>#</TableCell>

        {headersArray.map((headCell) => (
          <TableCell
            key={headCell.val + headCell.name + randomId()}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.name}
            {tableData.sortFields.includes(headCell.val) && (
              <Tooltip
                title={
                  sortDirection[headCell.val] === undefined
                    ? "Sort Descending"
                    : sortDirection[headCell.val] === "asc"
                    ? "Sort Ascending"
                    : "Sort Descending"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.val}
                  // active={true}
                  direction={orderBy === headCell.val ? order : "asc"}
                  onClick={createSortHandler(headCell.val, tableData, orderBy)}
                >
                  {orderBy === headCell.val ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            )}
          </TableCell>
        ))}
        {tableData.buttons !== undefined && tableData.buttons.length > 0 && (
          <TableCell padding="normal" align="center">
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const onCustomButtonClicked = ({ tableId, buttonId }) => {
  store.dispatch(setCustomButtonClicked({ tableId, buttonId }));
};

const onHandleReload = ({
  defaultReqBody,
  tableData,
  setSelected,
  reload,
  setSortDirection,
}) => {
  console.log("Reload===>", defaultReqBody);
  setSortDirection({});
  ////////////////// only for reload back to first page //////////////////////////////
  if (reload) {
    tableData.reqBody = cloneDeep(defaultReqBody);
    tableData.reqBody.condition.skip = 0;
  }
  ///////////////////////////////////////////////////////////////////////////////////
  // store.dispatch(fetchTableList({ BASE_URL: tableData.api.url, endPoint: tableData.api.endPoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } })).then(() => {
  //     store.dispatch(fetchTableCount({ BASE_URL: tableData.api.url, endPoint: tableData.api.tableCountEndpoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } }));
  // });

  requestSequencer({
    dispatch: store.dispatch,
    func: fetchTableList,
    args: {
      BASE_URL: tableData.api.url,
      endPoint: tableData.api.endPoint,
      reqBody: tableData.reqBody,
      restResponseAttachment: { tableId: tableData.tableId },
    },
  });
  requestSequencer({
    dispatch: store.dispatch,
    func: fetchTableCount,
    args: {
      BASE_URL: tableData.api.url,
      endPoint: tableData.api.tableCountEndpoint,
      reqBody: tableData.reqBody,
      restResponseAttachment: { tableId: tableData.tableId },
    },
  });

  setSelected([]);
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    tableData,
    setDrawerToggle,
    selected,
    setModalType,
    setSelected,
    setSortDirection,
    defaultReqBody,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableData.tableTitle}
        </Typography>
      )}
      {/* ----------- Custom Buttons of Table head only on multiple selection ----------------------- */}
      {numSelected > 0 &&
        tableData.customBtnHead !== undefined &&
        tableData.customBtnHead.length > 0 &&
        tableData.customBtnHead.map((btn) => {
          return (
            <Tooltip title={btn.title} key={randomId()}>
              <IconButton
                onClick={() =>
                  onCustomButtonClicked({
                    tableId: tableData.tableId,
                    buttonId: btn.id,
                  })
                }
              >
                <Icon>{btn.type}</Icon>
              </IconButton>
            </Tooltip>
          );
        })}

      {tableData.searchBarData && (
        <Tooltip title="Search list">
          <IconButton onClick={() => setDrawerToggle(true)}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Reload">
        <IconButton
          onClick={() =>
            onHandleReload({
              defaultReqBody,
              tableData,
              setSelected,
              reload: true,
              setSortDirection,
            })
          }
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ListingTable({ tableData, dataset, modifyHeaders }) {
  const [rows, setRows] = React.useState([]);
  console.log("in listing table ", dataset, tableData, modifyHeaders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      if (dataset.length > 0) {
        dispatch(setTableData({ dataset, tableId: tableData.tableId }));
      }
      /////////////////////// Sequence Endpoint Call //////////////////////////////////
      requestSequencer({
        dispatch: dispatch,
        func: fetchTableCount,
        args: {
          BASE_URL: tableData.api.url,
          endPoint: tableData.api.tableCountEndpoint,
          reqBody: tableData.reqBody,
          restResponseAttachment: { tableId: tableData.tableId },
        },
      });
      // dispatch(fetchTableCount({ BASE_URL: tableData.api.url, endPoint: tableData.api.tableCountEndpoint, reqBody: tableData.reqBody, restResponseAttachment: { tableId: tableData.tableId } }));
    }, 100);
  }, []);
  const [order, setOrder] = React.useState("asc");
  const [sortDirection, setSortDirection] = React.useState({});
  const [orderBy, setOrderBy] = React.useState(tableData.reqBody.sort.field);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modifyTableHeaders, setmodifyTableHeaders] = React.useState(
    modifyHeaders.length > 0 ? modifyHeaders : []
  );
  const [loading, setLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(dataset.length);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [drawerToggle, setDrawerToggle] = React.useState(false);
  const [reloadTableFlag, setReloadTableFlag] = React.useState(false);
  const [openSingleDelete, setOpenSingleDelete] = React.useState(false);
  const [defaultReqBody, setDefaultReqBody] = React.useState(null);

  //   setmodifyTableHeaders([...modifyTableHeaders])
  console.log("modifyTableHeaders++", modifyTableHeaders);
  headersArray = modifyHeaders;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  React.useEffect(() => {
    // const tableDataDefaultReqBody = Object.assign({}, tableData.reqBody);
    const tableDataDefaultReqBody = cloneDeep(tableData.reqBody);
    dispatch(
      setTableDefaultreqBody({
        reqBody: tableDataDefaultReqBody,
        tableId: tableData.tableId,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      setMultipleSeletedList({ tableId: tableData.tableId, rowIds: selected })
    );

    //////////// Clear Custom Button Clicked of Multiple Delete and Edit ///////////////
    if (tableData.customBtnHead !== undefined)
      tableData.customBtnHead.forEach((btn) => {
        if (
          selected.length === 0 &&
          store.getState().tableSlice.customButtonClicked[tableData.tableId] &&
          store.getState().tableSlice.customButtonClicked[tableData.tableId][
            btn.id
          ] !== undefined
        ) {
          dispatch(
            clearCustomButtonClicked({
              tableId: tableData.tableId,
              buttonId: btn.id,
            })
          );
        }
      });
  }, [selected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  React.useEffect(() => {
    console.log("Selected", selected);
  }, [selected]);

  //////////////////////////////////// ON PAGE CHANGE /////////////////////////////////////////////
  const handleChangePage = (event, newPage) => {
    console.log("NEW PAGE_________________--", newPage);
    let newSkip = null;
    if (tableData.paginationType === "stackPagination") {
      newSkip = tableData.reqBody.condition.limit * (newPage - 1);
      setPage(newPage - 1);
    } else {
      newSkip = tableData.reqBody.condition.limit * newPage;
      setPage(newPage);
    }
    console.log("NEW SKIP+++++++=");
    const reqBody = tableData.reqBody;
    reqBody.condition.skip = newSkip;

    onHandleReload({
      defaultReqBody,
      tableData,
      setSelected,
      setSortDirection,
    });
  };
  ////////////////////////////////////// ON PAGE ROWS COUNT CHANGE //////////////////////////////////
  const handleChangeRowsPerPage = (event) => {
    const newLimit = event.target.value;
    setRowsPerPage(newLimit);
    const reqBody = tableData.reqBody;
    reqBody.condition.limit = newLimit;
    reqBody.condition.skip = 0;

    onHandleReload({
      defaultReqBody,
      tableData,
      setSelected,
      setSortDirection,
    });
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  /////////////////// On Delete List Data //////////////////
  const onHandleDelete = (row, tableData) => {
    setOpenModal(true);
    setDeleteRow(row);
  };
  const handleConfirmDelete = async (confirm) => {
    if (confirm && deleteRow !== null) {
      await store.dispatch(
        deleteSingleTableData({
          BASE_URL: tableData.api.url,
          endPoint: tableData.api.deleteSingleUserEndpoint,
          reqBody: tableData.deleteSingleUserReqBody,
          restResponseAttachment: { tableId: tableData.tableId },
          restBodyAttachment: { id: deleteRow._id },
        })
      );

      onHandleReload({
        defaultReqBody,
        tableData,
        setSelected,
        setSortDirection,
      });
    }

    setOpenModal(false);
    setDeleteRow(null);
  };

  /////////////////// On View List Data //////////////////
  const onHandleView = (row, buttonId) => {
    dispatch(
      setViewData({
        tableId: tableData.tableId,
        viewData: row,
        buttonId: buttonId,
      })
    );
  };

  ////////////////////// On Edit List Data //////////////////
  const onHandleEdit = (row, buttonId) => {
    dispatch(
      setTempEditData({
        editData: row,
        tableId: tableData.tableId,
        buttonId: buttonId,
      })
    );
  };
  //////////////////////////// No Row Custom Button Clicked ////////////////////
  const onCustomBtnClickedRow = (row, buttonId) => {
    dispatch(setCustomButtonClicked({ tableId: tableData.tableId, buttonId }));
    dispatch(setTableRowData({ tableId: tableData.tableId, row }));
  };

  ///////////////////////// Action Button Functionality //////////////////////////
  const onRowActionButtonClick = ({
    row,
    buttonId,
    base_url,
    endPoint,
    body,
    payloadAttach,
    isArray,
  }) => {
    console.log(
      "On action button clicked",
      row,
      buttonId,
      base_url,
      endPoint,
      body,
      payloadAttach,
      isArray
    );
    dispatch(setTableRowData({ tableId: tableData.tableId, row }));
    let reqBody = { ...body };
    if (payloadAttach && payloadAttach.length > 0) {
      payloadAttach.forEach((cur) => {
        if (row[cur] !== undefined && row[cur])
          reqBody.searchcondition[cur] = row[cur];
      });
    }
    dispatch(
      onRowActionButtonClicked({
        BASE_URL: base_url,
        endPoint,
        reqBody,
        isArray,
        buttonId,
        tableId: tableData.tableId,
      })
    );
  };
  ///////////////////// link Button Functionality /////////////////////////////
  const onRowLinkButtonClick = ({
    row,
    targetBlank,
    queryParams,
    params,
    base_url,
    endPoint,
  }) => {
    console.log(
      "On link button Click",
      row,
      targetBlank,
      queryParams,
      params,
      base_url,
      endPoint
    );
    let url = `${endPoint}`;
    ///////////////////// Adding params in the url //////////////////////
    if (queryParams) {
      params.forEach((curParam, index) => {
        if (row[curParam] !== undefined) {
          //////////// Add ? or & ////////
          if (index === 0) url += `?`;
          else url += `&`;
          ////////// Add query ///////////////
          url += `${curParam}=${row[curParam]}`;
        }
      });
    } else {
      params.forEach((curParam) => {
        if (row[curParam] !== undefined) {
          ////////// Add params ///////////////
          url += `/${row[curParam]}`;
        }
      });
    }
    //////////////////////////// Open in Traget Balnk or Not ////////////////////////
    if (targetBlank) {
      window.open(`${base_url}${url}`, "_blank");
    } else {
      navigate(`/${url}`);
    }
  };

  const onHandleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDrawer = () => {
    setDrawerToggle(false);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  store.subscribe(() => {
    const storeData = store.getState();
    console.log("_+++++++++++++++++++++++++=");
    setRows(storeData.tableSlice.tableData[tableData.tableId]);
    setLoading(storeData.tableSlice.loading[tableData.tableId]);
    setRowsPerPage(tableData.reqBody.condition.limit);
    setOrderBy(tableData.reqBody.sort.field);
    setOrder(tableData.reqBody.sort.type);
    setPage(
      parseInt(
        tableData.reqBody.condition.skip / tableData.reqBody.condition.limit
      )
    );
    setReloadTableFlag(storeData.tableSlice.reloadTableFlag[tableData.tableId]);
    setDefaultReqBody(
      storeData.tableSlice.tableDefaultReqBody[tableData.tableId]
    );
    ////////////////////////////////// Delete Sanackbar /////////////////////////////
    if (
      storeData.tableSlice.deleteSingleDataSuccess[tableData.tableId] !==
        undefined &&
      storeData.tableSlice.deleteSingleDataSuccess[tableData.tableId] === 2
    ) {
      setOpenSingleDelete(true);
    } else {
      setOpenSingleDelete(false);
    }
    //////////////////////////////////////////////////////////////////////////////
    if (storeData.tableSlice.count[tableData.tableId] !== undefined) {
      setRowCount(storeData.tableSlice.count[tableData.tableId]);
    }
  });
  ///////////////////////// reset table single delete flag /////////////////////////////////
  React.useEffect(() => {
    if (openSingleDelete)
      setTimeout(
        () =>
          dispatch(resetDeleteSingleDataFlag({ tableId: tableData.tableId })),
        500
      );
  }, [openSingleDelete]);
  ////////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    console.log("defaultReqBody0000", defaultReqBody);
  }, [defaultReqBody]);

  React.useEffect(() => {
    if (reloadTableFlag === true) {
      onHandleReload({
        defaultReqBody,
        tableData,
        setSelected,
        setSortDirection,
      });
      dispatch(resetReloadTableFlag({ tableId: tableData.tableId }));
    }
  }, [reloadTableFlag]);

  React.useEffect(() => {
    console.log("row===>", rows);
  }, [rows]);

  return (
    <>
      <div className={"muiTable " + tableData.tableId}>
        {loading && (
          <Box sx={{ width: "100%" }} className="tableProgressBar">
            <LinearProgress />
          </Box>
        )}

        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected?.length}
              tableData={tableData}
              selected={selected}
              setDrawerToggle={setDrawerToggle}
              setSelected={setSelected}
              setSortDirection={setSortDirection}
              defaultReqBody={defaultReqBody}
            />
            {rows?.length > 0 ? (
              <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected?.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      tableData={tableData}
                      setPage={setPage}
                      setOrder={setOrder}
                      setSelected={setSelected}
                      sortDirection={sortDirection}
                      setSortDirection={setSortDirection}
                      defaultReqBody={defaultReqBody}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          console.log(
                            "data1111======>",
                            row,
                            "====",
                            headersArray
                          );
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row._id + randomId()}
                              selected={isItemSelected}
                            >
                              {tableData.customBtnHead !== undefined &&
                                tableData.customBtnHead.length > 0 && (
                                  <TableCell
                                    onClick={(event) =>
                                      handleClick(event, row._id)
                                    }
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell>
                                )}
                              {/* INDEXING */}
                              <TableCell>
                                {index + 1 + tableData.reqBody.condition.skip}
                              </TableCell>

                              {headersArray.map((data, index) => {
                                {
                                  /* console.log("data======>", data, row[data.val], "======", (row[data.val]).length) */
                                }
                                return (
                                  <TableCell
                                    key={
                                      row._id +
                                      "cell" +
                                      row[data.val] +
                                      index +
                                      randomId()
                                    }
                                    title={data.name}
                                    // align={headCell.numeric ? 'right' : 'left'}
                                    // padding={headCell.disablePadding ? 'none' : 'normal'}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        row[data.val] === null ||
                                        row[data.val] === undefined ||
                                        row[data.val].length === 0
                                          ? "N/A"
                                          : data?.type?.toLowerCase() ===
                                            "datetime"
                                          ? timeConverter(
                                              row[data.val],
                                              data.format
                                            )
                                          : row[data.val],
                                    }}
                                  ></TableCell>
                                );
                              })}
                              {tableData.buttons !== undefined &&
                                tableData.buttons.length > 0 && (
                                  <TableCell>
                                    {tableData.buttons.map((button, index) => {
                                      if (button.type === "delete") {
                                        return (
                                          <Tooltip
                                            key={index + randomId()}
                                            className={button.name}
                                            title={button.name}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                onHandleDelete(row, tableData)
                                              }
                                              disabled={selected.length > 0}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      }
                                      if (button.type === "edit") {
                                        return (
                                          <Tooltip
                                            key={index + randomId()}
                                            className={button.name}
                                            title={button.name}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                onHandleEdit(row, button.id)
                                              }
                                              disabled={selected.length > 0}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      }
                                      if (button.type === "view") {
                                        return (
                                          <Tooltip
                                            key={index + randomId()}
                                            className={button.name}
                                            title={button.name}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                onHandleView(row, button.id)
                                              }
                                              disabled={selected.length > 0}
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      }
                                      if (button.type === "other") {
                                        return (
                                          <Tooltip
                                            key={index + randomId()}
                                            className={button.name}
                                            title={button.label}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                onCustomBtnClickedRow(
                                                  row,
                                                  button.id
                                                )
                                              }
                                              disabled={selected.length > 0}
                                            >
                                              <Icon>{button.name}</Icon>
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      }
                                      //////////////// Action Buttons /////////////
                                      if (button.type === "action") {
                                        console.log(
                                          "conditional field===>",
                                          button.condField,
                                          button.condVal,
                                          row[button.condField]
                                        );
                                        if (
                                          row[button.condField] ===
                                          button.condVal
                                        )
                                          return (
                                            <Tooltip
                                              key={index + randomId()}
                                              className={button.name}
                                              title={button.name}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  onRowActionButtonClick({
                                                    row,
                                                    buttonId: button.id,
                                                    base_url:
                                                      button.base_url || "",
                                                    endPoint:
                                                      button.endpoint || "",
                                                    body: button.body || {},
                                                    payloadAttach:
                                                      button.payloadAttach ||
                                                      [],
                                                    isArray:
                                                      button.isArray || false,
                                                  })
                                                }
                                                disabled={selected.length > 0}
                                              >
                                                <Icon>{button.icon_type}</Icon>
                                              </IconButton>
                                            </Tooltip>
                                          );
                                      }
                                      /////////////////// Link Buttons ////////////////
                                      if (button.type === "link") {
                                        if (
                                          row[button.condField] ===
                                          button.condVal
                                        )
                                          return (
                                            <Tooltip
                                              key={index + randomId()}
                                              className={button.name}
                                              title={button.name}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  onRowLinkButtonClick({
                                                    row,
                                                    targetBlank:
                                                      button.targetBlank ||
                                                      false,
                                                    queryParams:
                                                      button.queryParams ||
                                                      false,
                                                    params: button.params || [],
                                                    base_url:
                                                      button.base_url || "",
                                                    endPoint:
                                                      button.endpoint || "",
                                                  })
                                                }
                                                disabled={selected.length > 0}
                                              >
                                                <Icon>{button.icon_type}</Icon>
                                              </IconButton>
                                            </Tooltip>
                                          );
                                      }
                                    })}
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                {tableData.paginationType === "stackPagination" ? (
                  <Stack spacing={2} className="stackPagination">
                    <div className="listCount">
                      Showing {1 + tableData.reqBody.condition.skip}-
                      {tableData.reqBody.condition.limit +
                        tableData.reqBody.condition.skip >
                      rowCount
                        ? rowCount
                        : tableData.reqBody.condition.limit +
                          tableData.reqBody.condition.skip}{" "}
                      of {rowCount}
                    </div>
                    <Pagination
                      count={Math.ceil(rowCount / rowsPerPage)}
                      variant="outlined"
                      shape="rounded"
                      page={page + 1}
                      onChange={handleChangePage}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    />
                  </Stack>
                ) : (
                  <TablePagination
                    rowsPerPageOptions={tableData.rowsPerPageOptions}
                    component="div"
                    count={rowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="tablePagination"
                  />
                )}
              </>
            ) : (
              <div
                className="noDataAvailable"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                No Data Found
              </div>
            )}
          </Paper>
          {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
        </Box>

        <Modal
          className={tableData?.deleteModal?.modalClassName}
          open={openModal}
          onClose={onHandleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="toggle_modal">
            <Box
              sx={
                tableData?.deleteModal?.modalStyle !== undefined
                  ? tableData?.deleteModal?.modalStyle
                  : {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                      textAlign: "center",
                    }
              }
            >
              {/* Close Button */}
              <IconButton
                className="modalCloseBtn"
                onClick={onHandleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              {/* Loader */}
              {loading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
              {/* For Delete Modal */}
              {
                <>
                  <h2>
                    Are you sure you want to delete this record? This can't be
                    undone.
                  </h2>
                  <div className="modal_button_Wrpr">
                    <Button onClick={() => handleConfirmDelete(true)}>
                      Yes
                    </Button>
                    <Button onClick={() => handleConfirmDelete(false)}>
                      No
                    </Button>
                  </div>
                </>
              }
            </Box>
          </div>
        </Modal>

        <Drawer anchor="bottom" open={drawerToggle} onClose={handleCloseDrawer}>
          <SearchBar
            tableData={tableData}
            searchBarData={tableData.searchBarData}
            tableId={tableData.tableId}
            handleCloseDrawer={handleCloseDrawer}
          />
        </Drawer>
      </div>

      {/* ------------- Single Delete Success message----------------------- */}

      <Snackbar
        className="singleDeleteSnackbar"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSingleDelete}
        onClose={() => setOpenSingleDelete(false)}
        autoHideDuration={1000}
        message="Deleted Successfully"
      />
    </>
  );
}
