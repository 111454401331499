import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const calllogin = createAsyncThunk("user/login", async (reqbody) => {

    console.log("reqbody==>", reqbody);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "userlogin", requestOptions);
    const respdata = await response.json();

    console.log("respdata after endpoint fetch==>", respdata);

    return respdata;

});

export const getipInfo = createAsyncThunk("user/getipInfo", async () => {
    console.log("process.env==>", process.env);

    const ipresponse = await fetch(process.env.REACT_APP_API_URL_IP);
    const ipInfo = await ipresponse.json();
    console.log('ipInfo==>', ipInfo);
    // console.log('ipresponse==>', ipresponse);
    ipInfo.browser = getbrowserinfo();
    return ipInfo;
});

const loginUser = createSlice({
    name: "loggedinuser",
    initialState: {
        loading: false,
        ipInfo: {},
        errorMessage: null,
        userInfo: {},
        secret: null,
        token: null,
        isLoggedIn: false,
        userType: null,
        last_login_time: null,
    },
    reducers: {
        setloginError(state, action) {
            state.errorMessage=null
        },
        setLogedinUserInfo(state, action) {
            console.log("state from setLogedinUserInfo==>", state);
            console.log("action.payload from setLogedinUserInfo==>", action.payload);
            if (action.payload !== undefined && action.payload.token!= null &&action.payload.userInfo!= null ) {
                state.userInfo = action.payload.userInfo;
                state.secret = action.payload.secret;
                state.token = action.payload.token;
                state.isLoggedIn = true;
                state.userType = action.payload.userInfo.type;
                state.last_login_time = action.payload.lastLoginTime;
            }
            console.log("state.userInfo setLogedinUserInfo==>", state.userInfo);
        },
        logOut(state, action) {
            console.log("state from logOut==>", state);
            console.log("payload from logOut==>", action);
            state.userInfo = {};
            state.secret = null;
            state.token = null;
            state.isLoggedIn = false;
            state.errorMessage = null;
            console.log("state from logOut after set==>", state);
        },
        setUserInitialInfo(state, action) {
            console.log()
        }
    },
    extraReducers: {
        [calllogin.pending]: (state, action) => {
            state.loading = true;
        },
        [calllogin.rejected]: (state, action) => {
            state.loading = false;
            state.isLoggedIn = false;
        },
        [calllogin.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("state from calllogin.fulfilled==>", state);
            console.log("action from calllogin.fulfilled==>", action);
            if (action.payload.status === 'error') {
                state.errorMessage = action.payload.message;
            }
            if (action.payload.status === 'success') {
                console.log('payload success==>', action.payload);
                state.userInfo = action.payload.item;
                // state.userinfo = action.payload.item;
                state.secret = action.payload.secret;
                state.token = action.payload.token;
                state.isLoggedIn = true;
                state.userType = action.payload.item.type;
                state.errorMessage = action.payload.message;
                state.last_login_time = action.payload.last_login_time;

                console.log("state from success part==>", state.userInfo);
            }
        },
        [getipInfo.fulfilled]: (state, action) => {
            console.log("action of getipInfo.fulfilled==>", action);
            state.ipInfo = action.payload;
            console.log("state of getipInfo.fulfilled==>", state.ipInfo);


        },
        
        
    },
});

export const { setLogedinUserInfo, logOut,setloginError } = loginUser.actions;

export default loginUser.reducer;


function getbrowserinfo() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
        return ('Opera');
    }
    else if (navigator.userAgent.indexOf("Edg") !== -1) {
        return ('Edge');
    }
    else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return ('Chrome');
    }
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
        return ('Safari');
    }
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return ('Firefox');
    }
    else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
    {
        return ('IE');
    }
    else {
        return ('unknown');
    }
}

