import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'

export const SelectSearch = ({ fielddata, setValue, getValues }) => {


  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl style={{ width: '100%', margin: "10px" }}>
        {/* Label */}
        {fielddata.label && <InputLabel >{fielddata.label}</InputLabel>}
        <Select
          label={fielddata.label}
          multiple={fielddata.multiple !== undefined && typeof (fielddata.multiple) === "boolean" ? fielddata.multiple : false}
          value={getValues(fielddata.field) ? getValues(fielddata.field) : (fielddata.multiple === true ? [] : '')}
          onChange={(e) => {
            setValue(fielddata.field, e.target.value);
          }}
          displayEmpty
        >
          {fielddata.values.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  )
}
