import React, { Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// import Home from "../src/pages/Home";
import Layout from "./layout";
// import Bio from "./pages/Bio";
// import Broadcasttrainings from "./pages/Broadcasttrainings";
// import Blog from "./pages/Blog";
// import Contact from "./pages/Contact";
import Login from "./tod_desmond_backend/Login";
import ForgetPassword from "./tod_desmond_backend/ForgetPassword";
import { Provider } from "react-redux";
import store from "./store";
import { DashBoard } from "./tod_desmond_backend/DashBoard";
import Header from "./layout/Header/Header";
import { MyAccount } from "./tod_desmond_backend/MyAccount";
import BlogAdd from "./tod_desmond_backend/BlogAdd";
import { setLogedinUserInfo } from "./tod_desmond_backend/LoginReducer";
import { Cookies, CookiesProvider } from "react-cookie";
// import BlogDetail from "./pages/BlogDetail";
import { ForgotPasswordPage } from "./tod_desmond_backend/ForgotPasswordPage";


const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());


const getcookies = cookies.getAll();
console.log("getcookies from index==>", getcookies);
if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
  console.log('getcookies==>', getcookies)
  store.dispatch(setLogedinUserInfo(getcookies));
}

const useAuth = () => {
  console.log("AUTHED++++++++++", store.getState().loginUserKey.isLoggedIn)
  return store.getState().loginUserKey.isLoggedIn;
}

const PrivateRoute = ({ children }) => {
  console.log("authed", useAuth());
  const auth = useAuth();
  return (
    auth === true ? children : <Navigate to="/login" replace={true} />
  )
}

const BackOfficeRoute = ({ children }) => {
  console.log("window================>", window.location.origin)
  return (
    window.location.origin !== "https://backoffice.psycheandsingularity.com" ? children : <Navigate to="/login" replace={true} />
  )
}

const LoginRoute = ({ children, redirectPath }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}
const PasswordRoute = ({ children }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to="/login" replace={true} />
}



// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<--------------------------React LAZY Loading Front End Pages------------------>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const Home = React.lazy(() => import('../src/pages/Home'))
const Bio = React.lazy(() => import('../src/pages/Bio'))
const Broadcasttrainings = React.lazy(() => import('../src/pages/Broadcasttrainings'))
const Blog = React.lazy(() => import('../src/pages/Blog'))
const Contact = React.lazy(() => import('../src/pages/Contact'))
const BlogDetail = React.lazy(() => import('../src/pages/BlogDetail'))





export default function App() {


  return (
    <CookiesProvider>

      <Provider store={store}>
        <BrowserRouter>
          <Routes>

            {/* <<<<<<<<<<<<<<<--------------------------------------------------FRONTEND PAGES------------------------------->>>>>>>>>>>>>>>>>>> */}

            <Route path='/' element={<Header />}>
              <Route index element={<Suspense fallback={<></>}><BackOfficeRoute><Home /></BackOfficeRoute></Suspense>} />{" "}
              <Route path="home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "}
              <Route path="bio" element={<Suspense fallback={<></>}><Bio /></Suspense>} />{" "}
              <Route path="broadcasttrainings" element={<Suspense fallback={<></>}><Broadcasttrainings /></Suspense>} />{" "}
              <Route path="blog" element={<Suspense fallback={<></>}><Blog /></Suspense>} />{" "}
              <Route path="blogDetail/:_id" element={<Suspense fallback={<></>}><BlogDetail /></Suspense>} />{" "}
              <Route path="contact" element={<Suspense fallback={<></>}><Contact /></Suspense>} />{" "}
            </Route>



            {/* <<<<<<<<<<<<<<<--------------------------------------------------BACKEND PAGES------------------------------->>>>>>>>>>>>>>>>>>> */}

            <Route path="/" element={<Layout />}>
              <Route index path="login" element={<LoginRoute redirectPath={`/dashboard`}><Login /></LoginRoute>} />{" "}
              <Route path="forgot-password" element={<ForgetPassword />} />
              <Route path="forgot-password/:_id/:token" element={<PasswordRoute><ForgotPasswordPage /></PasswordRoute>} />
              <Route path="dashboard" element={<PrivateRoute><DashBoard /></PrivateRoute>} />
              <Route path="myaccount" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
              <Route path="blogadd" element={<PrivateRoute><BlogAdd /></PrivateRoute>} />

            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>

  );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// ReactDOM.render(<App />, document.getElementById("root"));
