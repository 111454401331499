import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkHelper } from "../helper/helperFunctions";
// import store from "../store";

////////////////////////////////// Add Data into DB //////////////////////////////////////////////
export const addDataIntoDB = createAsyncThunk("form/addToDB", createAsyncThunkHelper)

////////////////////////////////// Fetch Auto Complete List //////////////////////////////////////
export const fetchAutoCompleteList = createAsyncThunk("form/fetchAutoCompleteList", createAsyncThunkHelper)

/////////////////////////////// Upload Image /////////////////////////////////////////////////////
export const uploadImage = createAsyncThunk("form/fileuploader", async (req) => {
  console.log("req file uploader======>", req)
})




const formSlice = createSlice({
  name: "form",
  initialState: {
    formFieldsData: {},
    formData: {},
    formError: {},
    autoCompleteList: {},
    loading: {},
    formSubmissionState: {},
    editorData: {},
    formResetFlag: {},
    tempFormUpdateData: {},
    tempFormFieldError: {}
  },
  reducers: {
    setFormFieldError(state, action) {
      console.log("action====>", action.payload)
      if (state.tempFormFieldError[action.payload.formId] === null || state.tempFormFieldError[action.payload.formId] === undefined) {
        state.tempFormFieldError[action.payload.formId] = {}
      }
      state.tempFormFieldError[action.payload.formId][action.payload.fieldName] = action.payload.error
    },
    resetFormFieldError(state, action) {
      if (state.tempFormFieldError[action.payload.formId][action.payload.fieldName]) delete state.tempFormFieldError[action.payload.formId][action.payload.fieldName]
    },
    setFormSubmissionStateWithoutEndpoint(state, action) {
      state.formSubmissionState[action.payload] = 4;
    },
    resetTempFormUpdateData(state, action) {
      if (state.tempFormUpdateData[action.payload.formId][action.payload.fieldName]) delete state.tempFormUpdateData[action.payload.formId][action.payload.fieldName]
    },
    setTempFormUpdateData(state, action) {
      console.log("action====>", action.payload)
      if (state.tempFormUpdateData[action.payload.formId] === null || state.tempFormUpdateData[action.payload.formId] === undefined) {
        state.tempFormUpdateData[action.payload.formId] = {}
      }
      if (state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] === null || state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] !== undefined) {
        state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] = "";
      }
      state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] = action.payload.value
    },
    setFormSubmissionStateStore(state, action) {
      console.log(action);
      state.formSubmissionState[action.payload] = 0;
    },
    setEditorData(state, action) {
      if (state.editorData[action.payload.formId] === undefined) {
        state.editorData[action.payload.formId] = {};
      }
      state.editorData[action.payload.formId][action.payload.fieldId] = action.payload.tempVar;
    },
    updateFormFieldsData(state, action) {
      console.log(action.payload);
      const temp = state.formFieldsData[action.payload.formId].fields.find(cur => cur.id === action.payload.fielddata.id);
      console.log(temp);
      if (temp) {
        temp.defaultValue = "<p>TEST</p>"
      }
      if (temp && temp.defaultValue !== action.payload.tempVar) {
        temp.defaultValue = action.payload.tempVar;
      }
    },
    setFormFieldsData(state, action) {
      console.log(action.payload);
      state.formFieldsData[action.payload.id] = action.payload;
    },
    setFormData(state, action) {
      // Defining new field against formId in error object if undefined
      if (state.formError[action.payload.formId] === undefined) {
        state.formError[action.payload.formId] = {};
      }


      //////////////////////////////////// Check if last name is valid //////////////////////////
      // if (action.payload.formId === 'userForm' && action.payload.lastname !== "Das") {
      //   // Assigning error msg against field name if undefinded 
      //   if (state.formError[action.payload.formId].lastname === undefined) {
      //     state.formError[action.payload.formId].lastname = 'Lastname is not valid';
      //   }
      // } else {
      //   // Deleting error field and msg against id
      //   delete state.formError[action.payload.formId].lastname;
      // }

      //////////////////////////////////// Setting Formdata into the state //////////////////////
      // Putting data inside the formData aginst formId
      state.formData[action.payload.formId] = action.payload;
    },
    resetForm(state, action) {
      state.formResetFlag[action.payload.formId] = true;
    },
    resetFormResetFlag(state, action) {
      state.formResetFlag[action.payload.formId] = false;
    }
  },
  extraReducers: {
    //////////////////////////// Fetch Auto Complete List /////////////////////////////////////
    [fetchAutoCompleteList.pending]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = true;
    },
    [fetchAutoCompleteList.fulfilled]: (state, action) => {
      state.loading[action.payload.autoCompleteId] = false;
      console.log(action.payload);
      const initialResult = [];
      const result = action.payload.results.res.reduce((acc, eachResult) => {
        acc.push({ key: eachResult.key, label: eachResult.label });
        return acc;
      }, initialResult);
      state.autoCompleteList[action.payload.autoCompleteId] = result;
    },
    [fetchAutoCompleteList.rejected]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = false;
    },
    ///////////////////////////// Add Data into DB ////////////////////////////
    [addDataIntoDB.pending]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.reqBody.formId] = 1;
    },
    [addDataIntoDB.fulfilled]: (state, action) => {
      state.formSubmissionState[action.meta.arg.reqBody.formId] = 2;
    },
    [addDataIntoDB.rejected]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.reqBody.formId] = 3;
    },
  },
});

export const { setFormFieldError, resetFormFieldError, setFormSubmissionStateWithoutEndpoint, resetTempFormUpdateData, setTempFormUpdateData, setFormData, resetForm, resetFormResetFlag, setFormFieldsData, updateFormFieldsData, setEditorData, setFormSubmissionStateStore } = formSlice.actions;

export default formSlice.reducer;
