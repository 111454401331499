import React from "react";


import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Fade, Icon, Menu } from "@mui/material";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import { logOut } from "../../tod_desmond_backend/LoginReducer";



const AdminHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const [selectMenu, setselectMenu] = React.useState(null);

  // const gotodashboard = () => {
  //   console.log("gotodashboard");
  //   navigate("/dashboard", { replace: true });
  //   var elem = document.getElementById("topMenuLists");
  //   elem.classList.toggle("addCSS");
  // };
 
 

  const handleClick = (val, event) => {
    setselectMenu(val);

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="admindash_wrapper">
        <div className="commonsection_backend">
          <div className="adminheader_subwrpr">
            <Grid item xs={12}  className="menulist_wrpr">
            <Grid item xs={12}  id="topMenuLists" className="topMenuLists">
              <div className="topMenuListsWrapper">

              {/* <Button
                  onClick={() => gotodashboard()}
                  className={pathname.includes("admin-dashboard") ? "active" : ""}
                >
                  Dashboard
                </Button> */}

                <Button  className={pathname.includes("admin-dashboard") ? "active" : ""} onClick={() => navigate('/dashboard')} >  Dashboard  </Button>

                {/* <Button  className={pathname.includes("admin-dashboard") ? "active" : ""} onClick={() => navigate('/bloglist')} >  Blog List  </Button> */}
             
                
              </div>
            </Grid>

        

            </Grid> 

            <Grid item xs={12}  className="rightmenu_wrpr">

            <Grid className="topRightMenu">
              <Button className="goToHome" onClick={() => navigate('/home')}>Go To Home</Button>
              <Button className="myAccount" id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(evt) => handleClick(7, evt)}><Icon className="account_circle">account_circle</Icon> <Icon className="expand_more">expand_more</Icon></Button>
            </Grid>
 </Grid> 

            {selectMenu === 7 && (
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => {
                  navigate("/myaccount", { replace: true });
                  setAnchorEl(false);
                }} className={pathname.includes("") ? "active" : ""}>My Account</MenuItem>
                <MenuItem onClick={() => {
                  dispatch(logOut())
                  const cookies = new Cookies();
                  cookies.remove("userInfo", { path: '/' });
                  cookies.remove("secret", { path: '/' });
                  cookies.remove("token", { path: '/' });
                  handleClose()
                  navigate('', { replace: true })
                }}>Log Out</MenuItem>
              </Menu>
            )}
          </div>
        </div>
      </div>



     
    </>
  );
};

export default AdminHeader;
