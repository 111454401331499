import { Button, FormHelperText, Snackbar, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AutoCompleteField } from './formComponents/AutoCompleteField';
import { DatePickerField } from './formComponents/DatePickerField';
import { DateRangePickerField } from './formComponents/DateRangePicker';
import { FileUploader } from './formComponents/FileUploader';
import { MultipleCheckBox } from './formComponents/MultipleCheckBox';
import { PasswordField } from './formComponents/PasswordField';
import { RadioSelection } from './formComponents/Radio';
import { SelectField } from './formComponents/Select';
import { SingleCheckbox } from './formComponents/SingleCheckbox';
import { Text } from './formComponents/Text';
import { TextArea } from './formComponents/TextArea';
import { TimePickerField } from './formComponents/TimePickerField';
import { addDataIntoDB, resetFormResetFlag, setFormData, setFormFieldsData, setFormSubmissionStateStore, setFormSubmissionStateWithoutEndpoint, } from './formReducer';
import store from '../store';
import { SwitchButton } from './formComponents/SwitchButton';
import { SunEditorField } from './formComponents/SunEditor';
import { HiddenField } from './formComponents/HiddenField';
import CachedIcon from '@mui/icons-material/Cached';
import debounce from 'lodash.debounce';
import { Youtube } from './formComponents/Youtube';
import { ChipTextBox } from './formComponents/ChipTextBox';


export const Form = ({ formData }) => {
  const [formState, setFormState] = useState({});////////////////// State only to rerender on default value set ///////
  const [formReset, setFormReset] = useState(false);
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    setError
  } = useForm();
  const { fields, formAdditionalSubmissionData } = formData;

  ////////////////////////////////////////// Set State on Initialization /////////////////////
  // dispatch(setFormFieldsData(formData));
  useEffect(() => {
    dispatch(setFormFieldsData(formData));
  }, [])

  ////////////////// Form Reset from outside ///////////////////
  useEffect(() => {
    if (formReset === true) {
      handleFormReset();
      dispatch(resetFormResetFlag({ formId: formData.id }));
    }
  }, [formReset])


  ///////////////////// On Form Submission ////////////////
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );



  useEffect(() => {
    if (formSubmissionState === 0) {
      console.log("Nothing Happening", formSubmissionState);
    }
    if (formSubmissionState === 1) {
      console.log("Form Submission Pending", formSubmissionState);
      setDisable(true);
      dispatch(setFormSubmissionStateStore(formData.id));
    }
    if (formSubmissionState === 2) {
      if (formData.urlPathOnSuccessfulFormSubmission !== undefined) {
        console.log("Form has been Submitted", formSubmissionState, formData.urlPathOnSuccessfulFormSubmission);
        navigate(formData.urlPathOnSuccessfulFormSubmission, { replace: true });
      }
      setDisable(false);
      dispatch(setFormSubmissionStateStore(formData.id));
    }
    if (formSubmissionState === 3) {
      console.log("Something Went Wrong", formSubmissionState)
      setDisable(false);
      dispatch(setFormSubmissionStateStore(formData.id));
      setOpen(true);

    }
  }, [formSubmissionState])

  ///////////////////////////////////// Form Data Check ////////////////////////////////
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(
        "value",
        value,
        "name",
        name,
        "type",
        type,
      )
      console.log("errors", errors)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  ///////////////// Used only for renrender on default value set ///////////////////
  useEffect(() => {
    setFormState(getValues());
  }, [])



  ///////////////////////////////////// SET STATE ON CHANGE ///////////////////////////////////
  ///// LOADASH DEBOUNCE ///
  const debouncedSetFormData = useCallback(debounce((value) => {
    dispatch(setFormData(value));
  }, 500), []);

  useEffect(() => {
    const subscribe = watch((value) => {
      console.log("WATCH____________---", store.getState().formSlice?.formFieldsData?.userForm?.fields);
      value.formId = formData.id;
      debouncedSetFormData(value);
    })

    return () => subscribe.unsubscribe();
  }, [watch]);

  ///////////////////////////////////// Check error on Form of Custom Validation ////////////////////////////


  ///////////////////////////////////// SET STATE ON SUBMIT /////////////////////////////////
  const onSubmit = (data) => {
    // const muiEditorData = store.getState().formSlice.formFieldsData[formData.id].fields.find(cur => cur.type === "muiRichTextEditor")?.defaultValue;
    // console.log("muiEditorData--------------", muiEditorData);
    // if (muiEditorData) data.muiRichTextEditor = muiEditorData; // Assign MUIRichTextEditor data on submit

    data.formId = formData.id;
    console.log(data);
    ////////////////////// Adding additional data on form submission /////////////////
    if (formAdditionalSubmissionData !== undefined && formAdditionalSubmissionData !== {}) {
      for (let eachData in formAdditionalSubmissionData) {
        if (formAdditionalSubmissionData[eachData] !== undefined && formAdditionalSubmissionData[eachData] !== null && formAdditionalSubmissionData[eachData] !== "") {
          data[eachData] = formAdditionalSubmissionData[eachData];
        }
      }
    }

    //////////////////// Storing Data in the Store //////////////////////
    dispatch(setFormData(data));
    /////////////////// Storing Data in the DB /////////////////////////////
    if (formData.endPoint !== undefined && formData.api_url !== undefined) {
      dispatch(
        addDataIntoDB({
          reqBody: data,
          BASE_URL: formData.api_url,
          endPoint: formData.endPoint,
          bodyWrapper: formData.endPointBodyWrapper,
          restResponseAttachment: { formId: formData.formId },
          endPointType: formData.endPointType
        })
      );
    } else {
      dispatch(setFormSubmissionStateWithoutEndpoint(formData.id));
    }
  };


  /////////////////////////////////// Get Form Data On Change ///////////////////////////////
  store.subscribe(() => {
    const storeData = store.getState();

    // ////////////////////////// Form State Back to initiialize //////////////////
    // if (storeData.formSlice.formSubmissionState !== 0) {

    // }
    //////////////////// Form reset flag /////////////////////
    if (storeData.formSlice.formResetFlag[formData.id] !== undefined) {
      setFormReset(storeData.formSlice.formResetFlag[formData.id]);
    }
    setFormSubmissionState(storeData.formSlice.formSubmissionState[formData.id]);


  })
  ////////////////////// Function to reset form //////////////////////
  const handleFormReset = () => {
    fields.forEach((field) => {
      if (field.defaultValue) reset({ [field.name]: field.defaultValue })
      else {
        switch (field.type) {
          case 'text':
            reset({ [field.name]: "" });

          case 'textarea':
            reset({ [field.name]: "" });

          case 'radio':
            reset({ [field.name]: "" });

          case 'multipleCheckbox':
            reset({ [field.name]: [] });

          case 'singleCheckbox':
            reset({ [field.name]: false });

          case 'select':
            reset({ [field.name]: null });

          case 'autoComplete':
            reset({ [field.name]: "" });

          case 'datePicker':
            reset({ [field.name]: null });

          case 'timePicker':
            reset({ [field.name]: null });

          case 'password':
            reset({ [field.name]: "" });

          case 'dateRangePicker':
            reset({ [field.name]: [] });

          case 'wysiwygEditor':
            reset({ [field.name]: "" });

          case "sunEditor":
            reset({ [field.name]: "" });

          case "muiRichTextEditor":
            reset({ [field.name]: "" });

          case "fileUploader":
            reset({ [field.name]: "" });

          case "switch":
            reset({ [field.name]: "" });

          case "html":
            reset({ [field.name]: "" });

          case "hidden":
            reset({ [field.name]: "" });

        }
      }
    })
  }

  return (
    <div id={formData.id} className={`${formData.formWrapperClass !== undefined ? formData.formWrapperClass : ""} formCls`}>
      <form onSubmit={handleSubmit(onSubmit)} className={`${formData.formInlineCLass !== undefined ? formData.formInlineCLass : ""} formInline`}>
        {
          fields.map(field => {
            switch (field.type) {
              case 'text':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Text formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'textarea':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextArea formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'radio':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioSelection formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'multipleCheckbox':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultipleCheckBox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'singleCheckbox':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleCheckbox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'select':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'autoComplete':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'datePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'timePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'password':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PasswordField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              /////////////////////////////////////// Implemented but without Pro version of MUI-X console error coming ////////////////////////
              case 'dateRangePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateRangePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case "sunEditor":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SunEditorField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />



              case "fileUploader":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FileUploader formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case "switch":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SwitchButton formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />


              case "html":
                return <div className={field.className} key={field.id} dangerouslySetInnerHTML={{ __html: field.value }}></div>

              case "hidden":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <HiddenField formId={formData.id} fielddata={field} setValue={setValue} />
                    )
                  }}
                />
              case "youtube":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Youtube formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />
              case "chipTextBox":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ChipTextBox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />


              default:
                return null;
            }
          })
        }
        <div className={(formData.formButtonClass !== undefined) ? formData.formButtonClass : ""}>
          <Button type='submit' className='submit' disabled={disable}><span>{formData.submitBtnName}</span></Button>
          {formData.resetForm && <Button onClick={() => handleFormReset()}>{formData.resetBtnName}</Button>}
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={formData.msgOnFailedFormSubmission}
        action={action}
      />
    </div>
  )
}
