import { accordionSummaryClasses } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//////////////////////////// For forgot password  ////////////////////////
export const forgetPassword = createAsyncThunk('login/forgotpassword', async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody)
  }
  console.log("reqBody====>", reqBody)
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/forgotpassword`, requestOptions);
  const res = response.json();
  return res;
})

const globalBackendReducer = createSlice({
  name: "globalBackendReducer",
  initialState: {
    userData:{},
  },
  reducers: {},
  extraReducers: {
    ////////////////////////////////forgotpassword/////////////////////
    [forgetPassword.pending]: (state, action) => {
    },
    [forgetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 'success') {
        state.usersCount = action.payload.result
        state.message = action.payload.message
      }
    },
    [forgetPassword.rejected]: (state, action) => {
      state.message = action.payload.message
    },
  },
});
export const { } = globalBackendReducer.actions;
export default globalBackendReducer.reducer;