import React, { useState } from "react";
import { Link, Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";

import NavigationIcon from '@mui/icons-material/Navigation';
 

const Footer = () => {
  const location = useLocation();

  const navigate = useNavigate();
 
   
 
   
  
  const scrollToTopLink = () =>{
    window.scrollTo({
      top: 0, 
      // behavior: 'smooth'
       
    });
  };

 

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };




  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };


  
  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  

  return (
    <>
    {(pathname==='/login'||pathname==='/forgot-password')?
    '':
    <div className="footer_mainwrpr">
    <div className="footer_section">
      <div className="copyright_banner commonsection">
        <div className="copyright_text">
          

          <div className="navsocial_wrpr">
            <nav className="navbar">
              <ul>
                {/* <li onClick={scrollToTopLink} className={splitLocation[1] === "" ? "active" : ""}>
                  <Link to="/"> HOME </Link>{" "}
                </li>{" "} */}

              <li> <NavLink exact to={"/home"} onClick={()=>{navigate('/home');scrollTop()}} className={splitLocation[1] === "home" ? "active" : ""} ><span>HOME</span></NavLink> </li>


                {/* <li  onClick={scrollToTopLink} className={splitLocation[1] === "bio" ? "active" : ""}>
                  <Link to="/bio"> BIO </Link>{" "}
                </li>{" "} */}

              <li> <NavLink exact to={"/bio"} onClick={()=>{navigate('/bio');scrollTop()}} className={splitLocation[1] === "bio" ? "active" : ""} ><span>BIO</span></NavLink> </li>

                {/* <li onClick={scrollToTopLink} 
                  className={
                    splitLocation[1] === "broadcasttrainings" ? "active" : ""
                  }
                >
                  <Link to="/broadcasttrainings"> BROADCAST TRAININGS </Link>{" "}
                </li>{" "} */}

              <li> <NavLink exact to={"/broadcasttrainings"} onClick={()=>{navigate('/broadcasttrainings');scrollTop()}} className={splitLocation[1] === "broadcasttrainings" ? "active" : ""} >
                <span>BROADCAST TRAININGS</span></NavLink> </li>
              

                {/* <li onClick={scrollToTopLink}  className={splitLocation[1] === "blog" ? "active" : ""}>
                  <Link to="/blog"> BLOG </Link>{" "}
                </li>{" "} */}

              <li> <NavLink exact to={"/blog"} onClick={()=>{navigate('/blog');scrollTop()}} className={splitLocation[1] === "blog" ? "active" : ""} ><span>BLOG</span></NavLink> </li>
                


                {/* <li onClick={scrollToTopLink} 
                  className={splitLocation[1] === "contact" ? "active" : ""}
                >
                  <Link to="/contact"> CONTACT </Link>{" "}
                </li>{" "} */}

              <li> <NavLink exact to={"/contact"} onClick={()=>{navigate('/contact');scrollTop()}} className={splitLocation[1] === "contact" ? "active" : ""} ><span>CONTACT</span></NavLink> </li>


              </ul>{" "}
            </nav>

            <div className="footer_socialconnect">
              {/* <img src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Twitter_logo.png" />
              <img src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Facebook_logo.png" />
              <img src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Linkedin_logo.png" /> */}

              <a href="https://twitter.com/" target="_blank">
                {" "}
                <img
                  src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Twitter_logo.png"
                  alt=""
                />{" "}
              </a>

              <a href="https://www.facebook.com/" target="_blank">
                {" "}
                <img
                  src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Facebook_logo.png"
                  alt=""
                />{" "}
              </a>

              <a href="https://www.linkedin.com/" target="_blank">
                {" "}
                <img
                  src="https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Linkedin_logo.png"
                  alt=""
                />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <div className="copyright_text_wrpr">
            <p>Copyright © 2000-2022 Timothy Desmond. All rights reserved.</p>
          </div>

  <NavigationIcon className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>

  </div>}
  


   
      </>
  );
};

export default Footer;
