import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../form/Form';
import { resetForm } from '../form/formReducer';
import store from '../store';

export const SetNewPassword = () => {
    const _id1 = useSelector((state) => state.loginUserKey.userInfo?._id);
    const [formSubmissionState, setFormSubmissionState] = useState(0);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        /////////////////// 1 for pending form submission //////////////////

        //////////////////// 2 for successful form submission //////////////////
        if (formSubmissionState === 2) {
            dispatch(resetForm({ formId: "passwordUpdateForm" }));
            setOpen(true);
        }
    }, [formSubmissionState]);


    store.subscribe(() => {
        const storeData = store.getState();
        //////////////////// Listener For form submission ///////////////////////
        setFormSubmissionState(
            storeData.formSlice.formSubmissionState.passwordUpdateForm
        );
    })
    const dataform = {
        id: 'passwordUpdateForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "api/updateuser",
        endPointBodyWrapper: "data",
        submitBtnName: "Update",
        formButtonClass: "submitbuttonmyacc",

        formAdditionalSubmissionData: {
            _id: (_id1 !== undefined) ? _id1 : undefined

        },

        fields: [
            ///////////////////////////////// Password /////////////////////////////////
            {
                id: 0,
                heading: "Old Password",
                label: "Old Password",
                // placeholder: "Old Password",
                name: "oldpassword",
                className: 'formGroup password inputcol-3',
                type: "password",
                // defaultValue: (typeof editdataforform.password !== undefined) ? editdataforform.password : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required"
                },
            },
            {
                id: 1,
                heading: "New Password",
                label: "New Password",
                name: "newpassword",
                className: 'formGroup password inputcol-3',
                type: "password",
                // defaultValue: (typeof editdataforform.password !== undefined) ? editdataforform.password : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required"
                },
            },
            {
                id: 2,
                heading: "Confirm New Password",
                label: "Confirm New Password",
                name: "confirmnewPassword",
                className: 'formGroup confirmPassword inputcol-3',
                type: "password",
                // defaultValue: (typeof editdataforform.confirmPassword !== undefined) ? editdataforform.confirmPassword : undefined,

                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    errorMessageConfirmPassword: "Password does not match" ////////////////////// Include this for confirm password field //////////
                },
            },
        ]
    };

    return (

        <div>
            <div className="blockInnerWrapper traning_list">
            <h1>Reset Password</h1>
                <div className="reactTableWrapper_reset">
                    <Form formData={dataform} />
                </div>
                <Snackbar
                    open={open}
                    onClose={() => setOpen(false)}
                    autoHideDuration={3000}
                    message="Password Changed Successfully"
                />
            </div>
        </div>

    )
}
